// THIS FILE IS AUTOGENERATED, EDIT WITH CAUTION
import {Node} from 'slate'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ContentModelSchema: { input: any; output: any; }
  DateTime: { input: string; output: string; }
  RichText: { input: Node[]; output: Node[]; }
  Unknown: { input: any; output: any; }
  Upload: { input: File; output: File; }
};

export type All = {
  __typename?: 'All';
  list: ListByTypeConnection;
  mediaTypes: Array<Scalars['String']['output']>;
  previewToken: Scalars['String']['output'];
  read: Array<ContentModelSummary>;
};


export type AllListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  context?: InputMaybe<ContentContextEnum>;
  filter?: InputMaybe<ContentFilter>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ContentSort>;
  type: ContentTypeEnum;
};


export type AllMediaTypesArgs = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type AllReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
};

export type AllMutations = {
  __typename?: 'AllMutations';
  archive: ContentModelSummary;
  delete?: Maybe<Scalars['Boolean']['output']>;
  emptyTrash?: Maybe<Array<Maybe<EmptyTrash>>>;
  moveToTrash: ContentModelSummary;
  publish: ContentModelSummary;
};


export type AllMutationsArchiveArgs = {
  id: Scalars['ID']['input'];
  inTrashCanBeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  revision: Scalars['Int']['input'];
};


export type AllMutationsDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type AllMutationsMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type AllMutationsPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};

export type Config = {
  __typename?: 'Config';
  content: Array<ContentConfig>;
  languages: LanguagesConfig;
};

export type ContentConfig = {
  __typename?: 'ContentConfig';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  schema: Scalars['ContentModelSchema']['output'];
};

export type ContentFilter = {
  options?: InputMaybe<Options>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type ContentModelSummary = {
  __typename?: 'ContentModelSummary';
  contentType: ContentTypeEnum;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export enum ContentSort {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum ContentStatusFilter {
  HideArchived = 'hideArchived',
  ShowAll = 'showAll',
  ShowArchived = 'showArchived',
  ShowDraft = 'showDraft',
  ShowPublic = 'showPublic',
  ShowTrashed = 'showTrashed'
}

export type CustomMutation = {
  __typename?: 'CustomMutation';
  mediaMultiUploadFile: MediaMultiUploadListItem;
  reindex?: Maybe<Migrate>;
  uploadBridgeProjects: UploadBridgeProjectsResponse;
};


export type CustomMutationMediaMultiUploadFileArgs = {
  input: MediaMultiUploadInput;
};


export type CustomMutationReindexArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CustomMutationUploadBridgeProjectsArgs = {
  input: UploadCsvInput;
};

export type CustomQuery = {
  __typename?: 'CustomQuery';
  mediaMultiUpload: Array<MediaMultiUploadListItem>;
};


export type CustomQueryMediaMultiUploadArgs = {
  fileList: Array<InputMaybe<Scalars['String']['input']>>;
};

export type EmptyTrash = {
  __typename?: 'EmptyTrash';
  id?: Maybe<Scalars['String']['output']>;
  revision?: Maybe<Scalars['Int']['output']>;
};

export type FilterDate = {
  /** less than */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** greater than */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** less than */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** range between two dates */
  range?: InputMaybe<RangeDateTime>;
};

export type FilterInt = {
  /** less than */
  eq?: InputMaybe<Scalars['Int']['input']>;
  /** greater than */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** less than */
  lt?: InputMaybe<Scalars['Int']['input']>;
};

export enum FilterOperator {
  And = 'AND',
  Or = 'OR'
}

export type FilterReference = {
  /** all */
  all?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in */
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** not in */
  nin?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FilterText = {
  /** equal to */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** not equal to */
  ne?: InputMaybe<Scalars['String']['input']>;
};

export enum ImageOutput {
  Jpeg = 'JPEG',
  Png = 'PNG',
  Webp = 'WEBP'
}

export enum ImageRotation {
  Auto = 'AUTO',
  Rotate_0 = 'ROTATE_0',
  Rotate_90 = 'ROTATE_90',
  Rotate_180 = 'ROTATE_180',
  Rotate_270 = 'ROTATE_270'
}

export type ImageTransformation = {
  height?: InputMaybe<Scalars['Int']['input']>;
  output?: InputMaybe<ImageOutput>;
  quality?: InputMaybe<Scalars['Float']['input']>;
  rotation?: InputMaybe<ImageRotation>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type InputPoint = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type LanguageConfig = {
  __typename?: 'LanguageConfig';
  description: Scalars['String']['output'];
  tag: Scalars['String']['output'];
};

export enum Languages {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it'
}

export type LanguagesConfig = {
  __typename?: 'LanguagesConfig';
  defaultLanguageTag: Scalars['String']['output'];
  languages: Array<LanguageConfig>;
};

export type LinkMandatory = {
  __typename?: 'LinkMandatory';
  link: LinkMandatory_Link;
};

export type LinkMandatory_Link = LinkMandatory_Link_External | LinkMandatory_Link_Internal;

export type LinkMandatory_Link_External = {
  __typename?: 'LinkMandatory_link_external';
  external?: Maybe<LinkMandatory_Link_External_Content>;
};

export type LinkMandatory_Link_External_Content = {
  __typename?: 'LinkMandatory_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<LinkMandatory_Link_External_Target>;
};

export enum LinkMandatory_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type LinkMandatory_Link_Internal = {
  __typename?: 'LinkMandatory_link_internal';
  internal?: Maybe<LinkMandatory_Link_Internal_Content>;
};

export type LinkMandatory_Link_Internal_Content = {
  __typename?: 'LinkMandatory_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type LinkOptional = {
  __typename?: 'LinkOptional';
  link: LinkOptional_Link;
};

export type LinkOptional_Link = LinkOptional_Link_External | LinkOptional_Link_Internal;

export type LinkOptional_Link_External = {
  __typename?: 'LinkOptional_link_external';
  external?: Maybe<LinkOptional_Link_External_Content>;
};

export type LinkOptional_Link_External_Content = {
  __typename?: 'LinkOptional_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<LinkOptional_Link_External_Target>;
};

export enum LinkOptional_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type LinkOptional_Link_Internal = {
  __typename?: 'LinkOptional_link_internal';
  internal?: Maybe<LinkOptional_Link_Internal_Content>;
};

export type LinkOptional_Link_Internal_Content = {
  __typename?: 'LinkOptional_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type MediaTypeFilter = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_BridgeProject = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_BridgeProjectInnovationField = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_BridgeProjectInstitution = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_BridgeProjectType = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_CategoryNews = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Contact = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Domain = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Event = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_EventType = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Faq = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Footer = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Media = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Module = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Multimedia = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Navigation = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_News = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Page = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Project = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Publication = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_PublicationType = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Tag = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MediaTypeFilter_Filter_Website = {
  model?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Migrate = {
  __typename?: 'Migrate';
  message: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  content: Content_Mutations;
  createSession: SessionWithToken;
  createSessionWithJWT: SessionWithToken;
  createUser?: Maybe<User>;
  createUserRole?: Maybe<UserRole>;
  deleteUser?: Maybe<Scalars['String']['output']>;
  deleteUserRole?: Maybe<Scalars['String']['output']>;
  extensions: CustomMutation;
  resetUserPassword?: Maybe<User>;
  revokeActiveSession: Scalars['Boolean']['output'];
  revokeSession: Scalars['Boolean']['output'];
  sessions: Array<Session>;
  updateEditorPreferences?: Maybe<Scalars['Boolean']['output']>;
  updateUser?: Maybe<User>;
  updateUserRole?: Maybe<UserRole>;
};


export type MutationCreateSessionArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateSessionWithJwtArgs = {
  jwt: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
  password: Scalars['String']['input'];
};


export type MutationCreateUserRoleArgs = {
  input: UserRoleInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetUserPasswordArgs = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRevokeSessionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateEditorPreferencesArgs = {
  preferences?: InputMaybe<UserEditorPreferencesInput>;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserInput;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID']['input'];
  input: UserRoleInput;
};

export type Options = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_BridgeProject = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_BridgeProject>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_BridgeProjectInnovationField = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_BridgeProjectInnovationField>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_BridgeProjectInstitution = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_BridgeProjectInstitution>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_BridgeProjectType = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_BridgeProjectType>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_CategoryNews = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_CategoryNews>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Contact = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Contact>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Domain = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Domain>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Event = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Event>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_EventType = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_EventType>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Faq = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Faq>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Footer = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Footer>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Media = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Media>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Module = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Module>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Multimedia = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Multimedia>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Navigation = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Navigation>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_News = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_News>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Page = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Page>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Project = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Project>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Publication = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Publication>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_PublicationType = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_PublicationType>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Tag = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Tag>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type Options_Filter_Website = {
  isAutocompleteSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isEditorSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isFuzzySearch?: InputMaybe<Scalars['Boolean']['input']>;
  isPhraseSearch?: InputMaybe<Scalars['Boolean']['input']>;
  mediaTypeFilter?: InputMaybe<MediaTypeFilter_Filter_Website>;
  searchInId?: InputMaybe<Scalars['Boolean']['input']>;
  searchInTitle?: InputMaybe<Scalars['Boolean']['input']>;
  statusFilter?: InputMaybe<ContentStatusFilter>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  deprecated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type Properties = {
  __typename?: 'Properties';
  key: Scalars['String']['output'];
  public: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type PropertiesInput = {
  key: Scalars['String']['input'];
  public: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export enum PublicationState {
  Archived = 'Archived',
  Draft = 'Draft',
  Published = 'Published',
  Trash = 'Trash'
}

export type Query = {
  __typename?: 'Query';
  config: Config;
  content: Content;
  editorPreferences?: Maybe<UserEditorPreferences>;
  extensions: CustomQuery;
  me?: Maybe<User>;
  permissions?: Maybe<Array<Permission>>;
  sessions: Array<Session>;
  user?: Maybe<User>;
  userRole?: Maybe<UserRole>;
  userRoles: UserRoleConnection;
  users: UserConnection;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  sort?: InputMaybe<UserRoleSort>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UserSort>;
};

export type RangeDateTime = {
  /** greater than */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** greater than or equal */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** less than */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** less than or equal */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type SessionWithToken = {
  __typename?: 'SessionWithToken';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
  user: User;
};

export type Size = Size_AspectRatio;

export type Size_AspectRatio = {
  __typename?: 'Size_aspectRatio';
  aspectRatio?: Maybe<Size_AspectRatio_Content>;
};

export type Size_AspectRatio_Content = {
  __typename?: 'Size_aspectRatio_content';
  desktop: Size_AspectRatio_Desktop;
  mobile: Size_AspectRatio_Mobile;
};

export enum Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type UploadCsvInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  address?: Maybe<UserAddress>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  modifiedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  preferredName?: Maybe<Scalars['String']['output']>;
  properties: Array<Properties>;
  roles: Array<UserRole>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  city: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  streetAddress2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type UserAddressInput = {
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEditorPreferences = {
  __typename?: 'UserEditorPreferences';
  i18nLanguage?: Maybe<Scalars['String']['output']>;
  uiLanguage?: Maybe<Scalars['String']['output']>;
};

export type UserEditorPreferencesInput = {
  i18nLanguage?: InputMaybe<Scalars['String']['input']>;
  uiLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type UserFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UserInput = {
  active: Scalars['Boolean']['input'];
  address?: InputMaybe<UserAddressInput>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  preferredName?: InputMaybe<Scalars['String']['input']>;
  properties: Array<PropertiesInput>;
  roleIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserRole = {
  __typename?: 'UserRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  modelPermissionIDs?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  systemRole: Scalars['Boolean']['output'];
};

export type UserRoleConnection = {
  __typename?: 'UserRoleConnection';
  nodes: Array<UserRole>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserRoleFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserRoleInput = {
  description: Scalars['String']['input'];
  modelPermissionIDs?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  permissionIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum UserRoleSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT'
}

export enum UserSort {
  CreatedAt = 'CREATED_AT',
  ModifiedAt = 'MODIFIED_AT',
  Name = 'NAME'
}

export type _CmpRefCase_BridgeProject = {
  __typename?: '_cmpRefCase_bridgeProject';
  bridgeProject?: Maybe<_Cmp_BridgeProject_Record>;
};

export type _CmpRefCase_BridgeProjectInnovationField = {
  __typename?: '_cmpRefCase_bridgeProjectInnovationField';
  bridgeProjectInnovationField?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
};

export type _CmpRefCase_BridgeProjectInstitution = {
  __typename?: '_cmpRefCase_bridgeProjectInstitution';
  bridgeProjectInstitution?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
};

export type _CmpRefCase_BridgeProjectType = {
  __typename?: '_cmpRefCase_bridgeProjectType';
  bridgeProjectType?: Maybe<_Cmp_BridgeProjectType_Record>;
};

export type _CmpRefCase_CategoryNews = {
  __typename?: '_cmpRefCase_categoryNews';
  categoryNews?: Maybe<_Cmp_CategoryNews_Record>;
};

export type _CmpRefCase_Contact = {
  __typename?: '_cmpRefCase_contact';
  contact?: Maybe<_Cmp_Contact_Record>;
};

export type _CmpRefCase_Domain = {
  __typename?: '_cmpRefCase_domain';
  domain?: Maybe<_Cmp_Domain_Record>;
};

export type _CmpRefCase_Event = {
  __typename?: '_cmpRefCase_event';
  event?: Maybe<_Cmp_Event_Record>;
};

export type _CmpRefCase_EventType = {
  __typename?: '_cmpRefCase_eventType';
  eventType?: Maybe<_Cmp_EventType_Record>;
};

export type _CmpRefCase_Faq = {
  __typename?: '_cmpRefCase_faq';
  faq?: Maybe<_Cmp_Faq_Record>;
};

export type _CmpRefCase_Footer = {
  __typename?: '_cmpRefCase_footer';
  footer?: Maybe<_Cmp_Footer_Record>;
};

export type _CmpRefCase_Media = {
  __typename?: '_cmpRefCase_media';
  media?: Maybe<_Cmp_Media_Record>;
};

export type _CmpRefCase_Module = {
  __typename?: '_cmpRefCase_module';
  module?: Maybe<_Cmp_Module_Record>;
};

export type _CmpRefCase_Multimedia = {
  __typename?: '_cmpRefCase_multimedia';
  multimedia?: Maybe<_Cmp_Multimedia_Record>;
};

export type _CmpRefCase_Navigation = {
  __typename?: '_cmpRefCase_navigation';
  navigation?: Maybe<_Cmp_Navigation_Record>;
};

export type _CmpRefCase_News = {
  __typename?: '_cmpRefCase_news';
  news?: Maybe<_Cmp_News_Record>;
};

export type _CmpRefCase_Page = {
  __typename?: '_cmpRefCase_page';
  page?: Maybe<_Cmp_Page_Record>;
};

export type _CmpRefCase_Project = {
  __typename?: '_cmpRefCase_project';
  project?: Maybe<_Cmp_Project_Record>;
};

export type _CmpRefCase_Publication = {
  __typename?: '_cmpRefCase_publication';
  publication?: Maybe<_Cmp_Publication_Record>;
};

export type _CmpRefCase_PublicationType = {
  __typename?: '_cmpRefCase_publicationType';
  publicationType?: Maybe<_Cmp_PublicationType_Record>;
};

export type _CmpRefCase_Tag = {
  __typename?: '_cmpRefCase_tag';
  tag?: Maybe<_Cmp_Tag_Record>;
};

export type _CmpRefCase_Website = {
  __typename?: '_cmpRefCase_website';
  website?: Maybe<_Cmp_Website_Record>;
};

export type _CmpRef_Richtext_References = _CmpRefCase_BridgeProject | _CmpRefCase_BridgeProjectInnovationField | _CmpRefCase_BridgeProjectInstitution | _CmpRefCase_BridgeProjectType | _CmpRefCase_CategoryNews | _CmpRefCase_Contact | _CmpRefCase_Domain | _CmpRefCase_Event | _CmpRefCase_EventType | _CmpRefCase_Faq | _CmpRefCase_Footer | _CmpRefCase_Media | _CmpRefCase_Module | _CmpRefCase_Multimedia | _CmpRefCase_Navigation | _CmpRefCase_News | _CmpRefCase_Page | _CmpRefCase_Project | _CmpRefCase_Publication | _CmpRefCase_PublicationType | _CmpRefCase_Tag | _CmpRefCase_Website;

export type _Cmp_BridgeProject = {
  __typename?: '_cmp_bridgeProject';
  list: _Cmp_BridgeProjectConnection;
  read?: Maybe<_Cmp_BridgeProject_Record>;
};


export type _Cmp_BridgeProjectListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_BridgeProject>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_BridgeProject>;
};


export type _Cmp_BridgeProjectReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_BridgeProjectConnection = {
  __typename?: '_cmp_bridgeProjectConnection';
  nodes: Array<Array<_Cmp_BridgeProject_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_BridgeProjectInnovationField = {
  __typename?: '_cmp_bridgeProjectInnovationField';
  list: _Cmp_BridgeProjectInnovationFieldConnection;
  read?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
};


export type _Cmp_BridgeProjectInnovationFieldListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_BridgeProjectInnovationField>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_BridgeProjectInnovationField>;
};


export type _Cmp_BridgeProjectInnovationFieldReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_BridgeProjectInnovationFieldConnection = {
  __typename?: '_cmp_bridgeProjectInnovationFieldConnection';
  nodes: Array<Array<_Cmp_BridgeProjectInnovationField_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_BridgeProjectInnovationField_Record = {
  __typename?: '_cmp_bridgeProjectInnovationField_record';
  content: _Cmp_BridgeProjectInnovationField_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_BridgeProjectInnovationField_Record_Content = {
  __typename?: '_cmp_bridgeProjectInnovationField_record_content';
  innovationField: Scalars['String']['output'];
};

export type _Cmp_BridgeProjectInstitution = {
  __typename?: '_cmp_bridgeProjectInstitution';
  list: _Cmp_BridgeProjectInstitutionConnection;
  read?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
};


export type _Cmp_BridgeProjectInstitutionListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_BridgeProjectInstitution>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_BridgeProjectInstitution>;
};


export type _Cmp_BridgeProjectInstitutionReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_BridgeProjectInstitutionConnection = {
  __typename?: '_cmp_bridgeProjectInstitutionConnection';
  nodes: Array<Array<_Cmp_BridgeProjectInstitution_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_BridgeProjectInstitution_Record = {
  __typename?: '_cmp_bridgeProjectInstitution_record';
  content: _Cmp_BridgeProjectInstitution_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_BridgeProjectInstitution_Record_Content = {
  __typename?: '_cmp_bridgeProjectInstitution_record_content';
  institution: Scalars['String']['output'];
};

export type _Cmp_BridgeProjectType = {
  __typename?: '_cmp_bridgeProjectType';
  list: _Cmp_BridgeProjectTypeConnection;
  read?: Maybe<_Cmp_BridgeProjectType_Record>;
};


export type _Cmp_BridgeProjectTypeListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_BridgeProjectType>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_BridgeProjectType>;
};


export type _Cmp_BridgeProjectTypeReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_BridgeProjectTypeConnection = {
  __typename?: '_cmp_bridgeProjectTypeConnection';
  nodes: Array<Array<_Cmp_BridgeProjectType_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_BridgeProjectType_Record = {
  __typename?: '_cmp_bridgeProjectType_record';
  content: _Cmp_BridgeProjectType_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_BridgeProjectType_Record_Content = {
  __typename?: '_cmp_bridgeProjectType_record_content';
  type: Scalars['String']['output'];
};

export type _Cmp_BridgeProject_Record = {
  __typename?: '_cmp_bridgeProject_record';
  content: _Cmp_BridgeProject_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_BridgeProject_Record_Content = {
  __typename?: '_cmp_bridgeProject_record_content';
  abstract: Scalars['String']['output'];
  coApplicant: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  innovationField?: Maybe<Ref__CmpRef_BridgeProjectInnovationField>;
  institution?: Maybe<Ref__CmpRef_BridgeProjectInstitution>;
  keywords: Array<Scalars['String']['output']>;
  projectNumber: Scalars['String']['output'];
  projectType?: Maybe<Ref__CmpRef_BridgeProjectType>;
  responsibleApplicant: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type _Cmp_CategoryNews = {
  __typename?: '_cmp_categoryNews';
  list: _Cmp_CategoryNewsConnection;
  read?: Maybe<_Cmp_CategoryNews_Record>;
};


export type _Cmp_CategoryNewsListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_CategoryNews>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_CategoryNews>;
};


export type _Cmp_CategoryNewsReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_CategoryNewsConnection = {
  __typename?: '_cmp_categoryNewsConnection';
  nodes: Array<Array<_Cmp_CategoryNews_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_CategoryNews_Record = {
  __typename?: '_cmp_categoryNews_record';
  content: _Cmp_CategoryNews_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_CategoryNews_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_CategoryNews_Record_Content = {
  __typename?: '_cmp_categoryNews_record_content';
  category: I18n_String;
  categorySingular: I18n_String;
  order: Scalars['Int']['output'];
};

export type _Cmp_CategoryNews_Record_Meta = {
  __typename?: '_cmp_categoryNews_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Contact = {
  __typename?: '_cmp_contact';
  list: _Cmp_ContactConnection;
  read?: Maybe<_Cmp_Contact_Record>;
};


export type _Cmp_ContactListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Contact>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Contact>;
};


export type _Cmp_ContactReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_ContactConnection = {
  __typename?: '_cmp_contactConnection';
  nodes: Array<Array<_Cmp_Contact_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Contact_Record = {
  __typename?: '_cmp_contact_record';
  content: _Cmp_Contact_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Contact_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Contact_Record_Content = {
  __typename?: '_cmp_contact_record_content';
  address?: Maybe<I18n_String>;
  eMail?: Maybe<Scalars['String']['output']>;
  info?: Maybe<I18n_RichText>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<I18n_String>;
};

export type _Cmp_Contact_Record_Meta = {
  __typename?: '_cmp_contact_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Domain = {
  __typename?: '_cmp_domain';
  list: _Cmp_DomainConnection;
  read?: Maybe<_Cmp_Domain_Record>;
};


export type _Cmp_DomainListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Domain>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Domain>;
};


export type _Cmp_DomainReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_DomainConnection = {
  __typename?: '_cmp_domainConnection';
  nodes: Array<Array<_Cmp_Domain_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Domain_Record = {
  __typename?: '_cmp_domain_record';
  content: _Cmp_Domain_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Domain_Record_Content = {
  __typename?: '_cmp_domain_record_content';
  domains: Array<Scalars['String']['output']>;
  forwards: Array<_Cmp_Domain_Record_Content_Forwards>;
  serve?: Maybe<Ref__CmpRef_Website>;
};

export type _Cmp_Domain_Record_Content_Forwards = {
  __typename?: '_cmp_domain_record_content_forwards';
  pattern: Scalars['String']['output'];
  regex: Scalars['Boolean']['output'];
  target: _Cmp_Domain_Record_Content_Forwards_Target;
};

export type _Cmp_Domain_Record_Content_Forwards_Target = _Cmp_Domain_Record_Content_Forwards_Target_External | _Cmp_Domain_Record_Content_Forwards_Target_Internal;

export type _Cmp_Domain_Record_Content_Forwards_Target_External = {
  __typename?: '_cmp_domain_record_content_forwards_target_external';
  external?: Maybe<_Cmp_Domain_Record_Content_Forwards_Target_External_Content>;
};

export type _Cmp_Domain_Record_Content_Forwards_Target_External_Content = {
  __typename?: '_cmp_domain_record_content_forwards_target_external_content';
  href: Scalars['String']['output'];
};

export type _Cmp_Domain_Record_Content_Forwards_Target_Internal = {
  __typename?: '_cmp_domain_record_content_forwards_target_internal';
  internal?: Maybe<_Cmp_Domain_Record_Content_Forwards_Target_Internal_Content>;
};

export type _Cmp_Domain_Record_Content_Forwards_Target_Internal_Content = {
  __typename?: '_cmp_domain_record_content_forwards_target_internal_content';
  language?: Maybe<_Cmp_Domain_Record_Content_Forwards_Target_Internal_Language>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
};

export enum _Cmp_Domain_Record_Content_Forwards_Target_Internal_Language {
  /** de */
  De = 'de',
  /** en */
  En = 'en',
  /** fr */
  Fr = 'fr',
  /** it */
  It = 'it'
}

export type _Cmp_Domain_Record_Content_Forwards_Target_Internal_Link = _CmpRefCase_Event | _CmpRefCase_Media | _CmpRefCase_Multimedia | _CmpRefCase_News | _CmpRefCase_Page | _CmpRefCase_Project;

export type _Cmp_Event = {
  __typename?: '_cmp_event';
  list: _Cmp_EventConnection;
  read?: Maybe<_Cmp_Event_Record>;
};


export type _Cmp_EventListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Event>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Event>;
};


export type _Cmp_EventReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_EventConnection = {
  __typename?: '_cmp_eventConnection';
  nodes: Array<Array<_Cmp_Event_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_EventType = {
  __typename?: '_cmp_eventType';
  list: _Cmp_EventTypeConnection;
  read?: Maybe<_Cmp_EventType_Record>;
};


export type _Cmp_EventTypeListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_EventType>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_EventType>;
};


export type _Cmp_EventTypeReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_EventTypeConnection = {
  __typename?: '_cmp_eventTypeConnection';
  nodes: Array<Array<_Cmp_EventType_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_EventType_Record = {
  __typename?: '_cmp_eventType_record';
  content: _Cmp_EventType_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_EventType_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_EventType_Record_Content = {
  __typename?: '_cmp_eventType_record_content';
  order: Scalars['Int']['output'];
  type: I18n_String;
};

export type _Cmp_EventType_Record_Meta = {
  __typename?: '_cmp_eventType_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Event_Record = {
  __typename?: '_cmp_event_record';
  content: _Cmp_Event_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Event_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Event_Record_Content = {
  __typename?: '_cmp_event_record_content';
  address?: Maybe<Scalars['String']['output']>;
  blocks: Array<_Cmp_Event_Record_Content_Blocks>;
  endDate: Scalars['DateTime']['output'];
  eventType?: Maybe<Ref__CmpRef_EventType>;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  leadText: I18n_String;
  module?: Maybe<Ref__CmpRef_Module>;
  organizer: I18n_String;
  place: I18n_String;
  placeShort: I18n_String;
  program?: Maybe<Array<_Cmp_Event_Record_Content_Program>>;
  registrationDeadline?: Maybe<Scalars['DateTime']['output']>;
  registrationFormSnippet: I18n_String;
  startDate: Scalars['DateTime']['output'];
  text: I18n_RichText;
  tileImage?: Maybe<_Cmp_Event_Record_Content_TileImage>;
  title: I18n_String;
  venueURL?: Maybe<I18n_String>;
};

export type _Cmp_Event_Record_Content_Blocks = _Cmp_Event_Record_Content_Blocks_Accordion | _Cmp_Event_Record_Content_Blocks_Anchor | _Cmp_Event_Record_Content_Blocks_CodeSnippet | _Cmp_Event_Record_Content_Blocks_CtaButton | _Cmp_Event_Record_Content_Blocks_CustomQuote | _Cmp_Event_Record_Content_Blocks_Image | _Cmp_Event_Record_Content_Blocks_LinkList | _Cmp_Event_Record_Content_Blocks_News | _Cmp_Event_Record_Content_Blocks_RichText | _Cmp_Event_Record_Content_Blocks_Spacer | _Cmp_Event_Record_Content_Blocks_Table;

export type _Cmp_Event_Record_Content_Blocks_Accordion = {
  __typename?: '_cmp_event_record_content_blocks_accordion';
  accordion?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion';
  content: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['output'];
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content = _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_News | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table;

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_anchor';
  anchor?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_faqList';
  faqList?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_image';
  image?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content>;
};

export enum _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_image_content';
  borderColor: _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList';
  linkList?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_content';
  linkList: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_linkList';
  link: _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External | _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal;

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_news';
  news?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_News_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_News_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_news_content';
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content>;
};

export enum _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_richText_content';
  backgroundColor?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table';
  table?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_cellWidth';
  content: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_content';
  cellWidth: _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText;

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_header_content_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_rows';
  content: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText;

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_rows_content_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_accordion_content_table_rows_content_richText_content';
  text: I18n_RichText;
};

export enum _Cmp_Event_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmp_Event_Record_Content_Blocks_Accordion_Content = {
  __typename?: '_cmp_event_record_content_blocks_accordion_content';
  accordion: Array<_Cmp_Event_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: Maybe<_Cmp_Event_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmp_Event_Record_Content_Blocks_Anchor = {
  __typename?: '_cmp_event_record_content_blocks_anchor';
  anchor?: Maybe<_Cmp_Event_Record_Content_Blocks_Anchor_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Anchor_Content = {
  __typename?: '_cmp_event_record_content_blocks_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_CodeSnippet = {
  __typename?: '_cmp_event_record_content_blocks_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Event_Record_Content_Blocks_CodeSnippet_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_CodeSnippet_Content = {
  __typename?: '_cmp_event_record_content_blocks_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_CtaButton = {
  __typename?: '_cmp_event_record_content_blocks_ctaButton';
  ctaButton?: Maybe<_Cmp_Event_Record_Content_Blocks_CtaButton_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_CtaButton_Content = {
  __typename?: '_cmp_event_record_content_blocks_ctaButton_content';
  buttonLabel: I18n_String;
  buttonLink: LinkMandatory;
  position: _Cmp_Event_Record_Content_Blocks_CtaButton_Position;
};

export enum _Cmp_Event_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmp_Event_Record_Content_Blocks_CustomQuote = {
  __typename?: '_cmp_event_record_content_blocks_customQuote';
  customQuote?: Maybe<_Cmp_Event_Record_Content_Blocks_CustomQuote_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_CustomQuote_Content = {
  __typename?: '_cmp_event_record_content_blocks_customQuote_content';
  author: Scalars['String']['output'];
  quote: I18n_String;
  source: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_Image = {
  __typename?: '_cmp_event_record_content_blocks_image';
  image?: Maybe<_Cmp_Event_Record_Content_Blocks_Image_Content>;
};

export enum _Cmp_Event_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Event_Record_Content_Blocks_Image_Content = {
  __typename?: '_cmp_event_record_content_blocks_image_content';
  borderColor: _Cmp_Event_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_LinkList = {
  __typename?: '_cmp_event_record_content_blocks_linkList';
  linkList?: Maybe<_Cmp_Event_Record_Content_Blocks_LinkList_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_LinkList_Content = {
  __typename?: '_cmp_event_record_content_blocks_linkList_content';
  linkList: Array<_Cmp_Event_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmp_Event_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList = {
  __typename?: '_cmp_event_record_content_blocks_linkList_linkList';
  link: _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link = _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External | _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal;

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_event_record_content_blocks_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_event_record_content_blocks_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_event_record_content_blocks_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_event_record_content_blocks_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Event_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Event_Record_Content_Blocks_News = {
  __typename?: '_cmp_event_record_content_blocks_news';
  news?: Maybe<_Cmp_Event_Record_Content_Blocks_News_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_News_Content = {
  __typename?: '_cmp_event_record_content_blocks_news_content';
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_Blocks_RichText = {
  __typename?: '_cmp_event_record_content_blocks_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_RichText_Content>;
};

export enum _Cmp_Event_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Event_Record_Content_Blocks_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_richText_content';
  backgroundColor?: Maybe<_Cmp_Event_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Event_Record_Content_Blocks_Spacer = {
  __typename?: '_cmp_event_record_content_blocks_spacer';
  spacer?: Maybe<_Cmp_Event_Record_Content_Blocks_Spacer_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Spacer_Content = {
  __typename?: '_cmp_event_record_content_blocks_spacer_content';
  height: Scalars['Int']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Table = {
  __typename?: '_cmp_event_record_content_blocks_table';
  table?: Maybe<_Cmp_Event_Record_Content_Blocks_Table_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_CellWidth = {
  __typename?: '_cmp_event_record_content_blocks_table_cellWidth';
  content: Array<_Cmp_Event_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_CellWidth_Content = {
  __typename?: '_cmp_event_record_content_blocks_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Table_Content = {
  __typename?: '_cmp_event_record_content_blocks_table_content';
  cellWidth: _Cmp_Event_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Event_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmp_Event_Record_Content_Blocks_Table_Rows>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_Header = {
  __typename?: '_cmp_event_record_content_blocks_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Event_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_Header_Content = _Cmp_Event_Record_Content_Blocks_Table_Header_Content_RichText;

export type _Cmp_Event_Record_Content_Blocks_Table_Header_Content_RichText = {
  __typename?: '_cmp_event_record_content_blocks_table_header_content_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Event_Record_Content_Blocks_Table_Rows = {
  __typename?: '_cmp_event_record_content_blocks_table_rows';
  content: Array<_Cmp_Event_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Event_Record_Content_Blocks_Table_Rows_Content = _Cmp_Event_Record_Content_Blocks_Table_Rows_Content_RichText;

export type _Cmp_Event_Record_Content_Blocks_Table_Rows_Content_RichText = {
  __typename?: '_cmp_event_record_content_blocks_table_rows_content_richText';
  richText?: Maybe<_Cmp_Event_Record_Content_Blocks_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Event_Record_Content_Blocks_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_event_record_content_blocks_table_rows_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Event_Record_Content_Program = {
  __typename?: '_cmp_event_record_content_program';
  startEndTime: Scalars['String']['output'];
  subTitle: I18n_String;
  text: I18n_String;
  title: I18n_String;
};

export type _Cmp_Event_Record_Content_TileImage = {
  __typename?: '_cmp_event_record_content_tileImage';
  tileImage?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmp_Event_Record_Meta = {
  __typename?: '_cmp_event_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  columnRightNew?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Event_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew = {
  __typename?: '_cmp_event_record_meta_columnRightNew';
  columnRight: Array<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['output'];
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight = _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button | _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact | _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Text | _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Title;

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button';
  button?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_buttonLink';
  link: _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External | _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal;

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_buttonLink_link_external';
  external?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_buttonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal';
  internal?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_button_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_contact';
  contact?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_contact_content';
  contact?: Maybe<Ref__CmpRef_Contact>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_text';
  text?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Text_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Text_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_text_content';
  infoBox: I18n_RichText;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_title';
  title?: Maybe<_Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Title_Content>;
};

export type _Cmp_Event_Record_Meta_ColumnRightNew_ColumnRight_Title_Content = {
  __typename?: '_cmp_event_record_meta_columnRightNew_columnRight_title_content';
  title: I18n_String;
};

export enum _Cmp_Event_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Faq = {
  __typename?: '_cmp_faq';
  list: _Cmp_FaqConnection;
  read?: Maybe<_Cmp_Faq_Record>;
};


export type _Cmp_FaqListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Faq>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Faq>;
};


export type _Cmp_FaqReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_FaqConnection = {
  __typename?: '_cmp_faqConnection';
  nodes: Array<Array<_Cmp_Faq_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Faq_Record = {
  __typename?: '_cmp_faq_record';
  content: _Cmp_Faq_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Faq_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Faq_Record_Content = {
  __typename?: '_cmp_faq_record_content';
  anchorName: I18n_String;
  answer: I18n_RichText;
  question: I18n_String;
};

export type _Cmp_Faq_Record_Meta = {
  __typename?: '_cmp_faq_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
  tags?: Maybe<Array<Maybe<Ref__CmpRef_Tag>>>;
};

export type _Cmp_Footer = {
  __typename?: '_cmp_footer';
  list: _Cmp_FooterConnection;
  read?: Maybe<_Cmp_Footer_Record>;
};


export type _Cmp_FooterListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Footer>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Footer>;
};


export type _Cmp_FooterReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_FooterConnection = {
  __typename?: '_cmp_footerConnection';
  nodes: Array<Array<_Cmp_Footer_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Footer_Record = {
  __typename?: '_cmp_footer_record';
  content: _Cmp_Footer_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Footer_Record_Content = {
  __typename?: '_cmp_footer_record_content';
  additionalLogo?: Maybe<_Cmp_Footer_Record_Content_AdditionalLogo>;
  copyright: I18n_String;
  newsletterButtonLabel: I18n_String;
  newsletterButtonLink?: Maybe<_Cmp_Footer_Record_Content_NewsletterButtonLink>;
  section2: I18n_RichText;
  section2Logo?: Maybe<_Cmp_Footer_Record_Content_Section2Logo>;
  section2Title: I18n_String;
  section3: I18n_RichText;
  section3Title: I18n_String;
  section4: I18n_RichText;
  section4Title: I18n_String;
  socialMedia: Array<_Cmp_Footer_Record_Content_SocialMedia>;
  subMenu: _Cmp_Footer_Record_Content_SubMenu;
};

export type _Cmp_Footer_Record_Content_AdditionalLogo = {
  __typename?: '_cmp_footer_record_content_additionalLogo';
  link?: Maybe<LinkOptional>;
  logo?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmp_Footer_Record_Content_NewsletterButtonLink = {
  __typename?: '_cmp_footer_record_content_newsletterButtonLink';
  link: _Cmp_Footer_Record_Content_NewsletterButtonLink_Link;
};

export type _Cmp_Footer_Record_Content_NewsletterButtonLink_Link = _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External | _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_Internal;

export type _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External = {
  __typename?: '_cmp_footer_record_content_newsletterButtonLink_link_external';
  external?: Maybe<_Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External_Content>;
};

export type _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External_Content = {
  __typename?: '_cmp_footer_record_content_newsletterButtonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External_Target>;
};

export enum _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_Internal = {
  __typename?: '_cmp_footer_record_content_newsletterButtonLink_link_internal';
  internal?: Maybe<_Cmp_Footer_Record_Content_NewsletterButtonLink_Link_Internal_Content>;
};

export type _Cmp_Footer_Record_Content_NewsletterButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_footer_record_content_newsletterButtonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Footer_Record_Content_Section2Logo = {
  __typename?: '_cmp_footer_record_content_section2Logo';
  link?: Maybe<LinkOptional>;
  logo?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmp_Footer_Record_Content_SocialMedia = {
  __typename?: '_cmp_footer_record_content_socialMedia';
  link: I18n_String;
  platform: _Cmp_Footer_Record_Content_SocialMedia_Platform;
};

export enum _Cmp_Footer_Record_Content_SocialMedia_Platform {
  /** Facebook */
  Facebook = 'facebook',
  /** Instagram */
  Instagram = 'instagram',
  /** LinkedIn */
  LinkedIn = 'linkedIn',
  /** Mastodon */
  Mastodon = 'mastodon',
  /** Twitter */
  Twitter = 'twitter',
  /** Xing */
  Xing = 'xing',
  /** Youtube */
  Youtube = 'youtube'
}

export type _Cmp_Footer_Record_Content_SubMenu = {
  __typename?: '_cmp_footer_record_content_subMenu';
  linkList: Array<_Cmp_Footer_Record_Content_SubMenu_LinkList>;
};

export type _Cmp_Footer_Record_Content_SubMenu_LinkList = {
  __typename?: '_cmp_footer_record_content_subMenu_linkList';
  link: _Cmp_Footer_Record_Content_SubMenu_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Footer_Record_Content_SubMenu_LinkList_Link = _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External | _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_Internal;

export type _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External = {
  __typename?: '_cmp_footer_record_content_subMenu_linkList_link_external';
  external?: Maybe<_Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External_Content>;
};

export type _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External_Content = {
  __typename?: '_cmp_footer_record_content_subMenu_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External_Target>;
};

export enum _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_Internal = {
  __typename?: '_cmp_footer_record_content_subMenu_linkList_link_internal';
  internal?: Maybe<_Cmp_Footer_Record_Content_SubMenu_LinkList_Link_Internal_Content>;
};

export type _Cmp_Footer_Record_Content_SubMenu_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_footer_record_content_subMenu_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Media = {
  __typename?: '_cmp_media';
  list: _Cmp_MediaConnection;
  read?: Maybe<_Cmp_Media_Record>;
};


export type _Cmp_MediaListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Media>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Media>;
};


export type _Cmp_MediaReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_MediaConnection = {
  __typename?: '_cmp_mediaConnection';
  nodes: Array<Array<_Cmp_Media_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Media_Record = {
  __typename?: '_cmp_media_record';
  content: _Cmp_Media_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Media_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Media_Record_Content = {
  __typename?: '_cmp_media_record_content';
  altText: I18n_String;
  license: Scalars['String']['output'];
  media?: Maybe<I18n_Media>;
  source: Scalars['String']['output'];
  title: I18n_String;
};

export type _Cmp_Media_Record_Meta = {
  __typename?: '_cmp_media_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Media_Record_Meta_RobotsMetaDirectives>;
};

export enum _Cmp_Media_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Module = {
  __typename?: '_cmp_module';
  list: _Cmp_ModuleConnection;
  read?: Maybe<_Cmp_Module_Record>;
};


export type _Cmp_ModuleListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Module>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Module>;
};


export type _Cmp_ModuleReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_ModuleConnection = {
  __typename?: '_cmp_moduleConnection';
  nodes: Array<Array<_Cmp_Module_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Module_Record = {
  __typename?: '_cmp_module_record';
  content: _Cmp_Module_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Module_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Module_Record_Content = {
  __typename?: '_cmp_module_record_content';
  category: I18n_String;
  order: Scalars['Int']['output'];
};

export type _Cmp_Module_Record_Meta = {
  __typename?: '_cmp_module_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Multimedia = {
  __typename?: '_cmp_multimedia';
  list: _Cmp_MultimediaConnection;
  read?: Maybe<_Cmp_Multimedia_Record>;
};


export type _Cmp_MultimediaListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Multimedia>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Multimedia>;
};


export type _Cmp_MultimediaReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_MultimediaConnection = {
  __typename?: '_cmp_multimediaConnection';
  nodes: Array<Array<_Cmp_Multimedia_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Multimedia_Record = {
  __typename?: '_cmp_multimedia_record';
  content: _Cmp_Multimedia_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Multimedia_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Multimedia_Record_Content = {
  __typename?: '_cmp_multimedia_record_content';
  content: _Cmp_Multimedia_Record_Content_Content;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  leadText: I18n_String;
  newsDate: Scalars['DateTime']['output'];
  shortLeadText: I18n_String;
  title: I18n_String;
};

export type _Cmp_Multimedia_Record_Content_Content = _Cmp_Multimedia_Record_Content_Content_Gallery | _Cmp_Multimedia_Record_Content_Content_Video;

export type _Cmp_Multimedia_Record_Content_Content_Gallery = {
  __typename?: '_cmp_multimedia_record_content_content_gallery';
  gallery?: Maybe<_Cmp_Multimedia_Record_Content_Content_Gallery_Content>;
};

export type _Cmp_Multimedia_Record_Content_Content_Gallery_Content = {
  __typename?: '_cmp_multimedia_record_content_content_gallery_content';
  images: Array<Maybe<_Cmp_Multimedia_Record_Content_Content_Gallery_Images>>;
};

export type _Cmp_Multimedia_Record_Content_Content_Gallery_Images = {
  __typename?: '_cmp_multimedia_record_content_content_gallery_images';
  borderColor: _Cmp_Multimedia_Record_Content_Content_Gallery_Images_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export enum _Cmp_Multimedia_Record_Content_Content_Gallery_Images_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Multimedia_Record_Content_Content_Video = {
  __typename?: '_cmp_multimedia_record_content_content_video';
  video?: Maybe<_Cmp_Multimedia_Record_Content_Content_Video_Content>;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Content = {
  __typename?: '_cmp_multimedia_record_content_content_video_content';
  video: _Cmp_Multimedia_Record_Content_Content_Video_Video;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Video = {
  __typename?: '_cmp_multimedia_record_content_content_video_video';
  type: _Cmp_Multimedia_Record_Content_Content_Video_Video_Type;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Video_Type = _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo | _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo;

export type _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo = {
  __typename?: '_cmp_multimedia_record_content_content_video_video_type_vimeoVideo';
  vimeoVideo?: Maybe<_Cmp_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo_Content>;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo_Content = {
  __typename?: '_cmp_multimedia_record_content_content_video_video_type_vimeoVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo = {
  __typename?: '_cmp_multimedia_record_content_content_video_video_type_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo_Content>;
};

export type _Cmp_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo_Content = {
  __typename?: '_cmp_multimedia_record_content_content_video_video_type_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Multimedia_Record_Meta = {
  __typename?: '_cmp_multimedia_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  columnRightNew?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Multimedia_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew';
  columnRight: Array<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['output'];
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight = _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button | _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact | _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text | _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title;

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button';
  button?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_buttonLink';
  link: _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External | _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal;

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_buttonLink_link_external';
  external?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_buttonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal';
  internal?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_button_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_contact';
  contact?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_contact_content';
  contact?: Maybe<Ref__CmpRef_Contact>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_text';
  text?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_text_content';
  infoBox: I18n_RichText;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_title';
  title?: Maybe<_Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title_Content>;
};

export type _Cmp_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title_Content = {
  __typename?: '_cmp_multimedia_record_meta_columnRightNew_columnRight_title_content';
  title: I18n_String;
};

export enum _Cmp_Multimedia_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Navigation = {
  __typename?: '_cmp_navigation';
  list: _Cmp_NavigationConnection;
  read?: Maybe<_Cmp_Navigation_Record>;
};


export type _Cmp_NavigationListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Navigation>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Navigation>;
};


export type _Cmp_NavigationReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_NavigationConnection = {
  __typename?: '_cmp_navigationConnection';
  nodes: Array<Array<_Cmp_Navigation_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Navigation_Record = {
  __typename?: '_cmp_navigation_record';
  content: _Cmp_Navigation_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Navigation_Record_Content = {
  __typename?: '_cmp_navigation_record_content';
  navigation: Array<_Cmp_Navigation_Record_Content_Navigation>;
};

export type _Cmp_Navigation_Record_Content_Navigation = {
  __typename?: '_cmp_navigation_record_content_navigation';
  childContents: Array<Maybe<Ref__CmpRef_Page_Multimedia>>;
  children?: Maybe<Array<_Cmp_Navigation_Record_Content_Navigation_Children>>;
  expand?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inactive?: Maybe<I18n_Boolean>;
  label: Scalars['String']['output'];
  labelI18n: I18n_String;
  layer?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Link>;
  parentForContentOfType?: Maybe<_Cmp_Navigation_Record_Content_Navigation_ParentForContentOfType>;
  refKey?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type _Cmp_Navigation_Record_Content_Navigation_ChildContents = _CmpRefCase_Multimedia | _CmpRefCase_Page;

export type _Cmp_Navigation_Record_Content_Navigation_Children = {
  __typename?: '_cmp_navigation_record_content_navigation_children';
  childContents: Array<Maybe<Ref__CmpRef_Page_Multimedia>>;
  children?: Maybe<Array<_Cmp_Navigation_Record_Content_Navigation_Children_Children>>;
  expand?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inactive?: Maybe<I18n_Boolean>;
  label: Scalars['String']['output'];
  labelI18n: I18n_String;
  layer?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Link>;
  parentForContentOfType?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_ParentForContentOfType>;
  refKey?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children';
  childContents: Array<Maybe<Ref__CmpRef_Page_Multimedia>>;
  expand?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inactive?: Maybe<I18n_Boolean>;
  label: Scalars['String']['output'];
  labelI18n: I18n_String;
  layer?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Children_Link>;
  parentForContentOfType?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Children_ParentForContentOfType>;
  refKey?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children_link';
  link: _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link = _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External | _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal;

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children_link_link_external';
  external?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children_link_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Target>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children_link_link_internal';
  internal?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_children_children_link_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_Children_Children_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link = {
  __typename?: '_cmp_navigation_record_content_navigation_children_link';
  link: _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link = _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External | _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_Internal;

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External = {
  __typename?: '_cmp_navigation_record_content_navigation_children_link_link_external';
  external?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_children_link_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External_Target>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_Internal = {
  __typename?: '_cmp_navigation_record_content_navigation_children_link_link_internal';
  internal?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_Internal_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Children_Link_Link_Internal_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_children_link_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_Children_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmp_Navigation_Record_Content_Navigation_Link = {
  __typename?: '_cmp_navigation_record_content_navigation_link';
  link: _Cmp_Navigation_Record_Content_Navigation_Link_Link;
};

export type _Cmp_Navigation_Record_Content_Navigation_Link_Link = _Cmp_Navigation_Record_Content_Navigation_Link_Link_External | _Cmp_Navigation_Record_Content_Navigation_Link_Link_Internal;

export type _Cmp_Navigation_Record_Content_Navigation_Link_Link_External = {
  __typename?: '_cmp_navigation_record_content_navigation_link_link_external';
  external?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Link_Link_External_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Link_Link_External_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_link_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Link_Link_External_Target>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Navigation_Record_Content_Navigation_Link_Link_Internal = {
  __typename?: '_cmp_navigation_record_content_navigation_link_link_internal';
  internal?: Maybe<_Cmp_Navigation_Record_Content_Navigation_Link_Link_Internal_Content>;
};

export type _Cmp_Navigation_Record_Content_Navigation_Link_Link_Internal_Content = {
  __typename?: '_cmp_navigation_record_content_navigation_link_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Navigation_Record_Content_Navigation_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmp_News = {
  __typename?: '_cmp_news';
  list: _Cmp_NewsConnection;
  read?: Maybe<_Cmp_News_Record>;
};


export type _Cmp_NewsListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_News>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_News>;
};


export type _Cmp_NewsReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_NewsConnection = {
  __typename?: '_cmp_newsConnection';
  nodes: Array<Array<_Cmp_News_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_News_Record = {
  __typename?: '_cmp_news_record';
  content: _Cmp_News_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_News_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_News_Record_Content = {
  __typename?: '_cmp_news_record_content';
  blocks: Array<_Cmp_News_Record_Content_Blocks>;
  /** @deprecated Use entryDates instead. entryDates supports multiple dates */
  entryDate?: Maybe<Scalars['DateTime']['output']>;
  entryDateText?: Maybe<I18n_String>;
  entryDates?: Maybe<Array<Scalars['DateTime']['output']>>;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  leadText: I18n_String;
  module?: Maybe<Ref__CmpRef_Module>;
  newsDate: Scalars['DateTime']['output'];
  shortLeadText: I18n_String;
  teaserTitle: I18n_String;
  title: I18n_String;
  video?: Maybe<_Cmp_News_Record_Content_Video>;
};

export type _Cmp_News_Record_Content_Blocks = _Cmp_News_Record_Content_Blocks_Accordion | _Cmp_News_Record_Content_Blocks_Anchor | _Cmp_News_Record_Content_Blocks_CodeSnippet | _Cmp_News_Record_Content_Blocks_CtaButton | _Cmp_News_Record_Content_Blocks_CustomQuote | _Cmp_News_Record_Content_Blocks_Embed | _Cmp_News_Record_Content_Blocks_FaqList | _Cmp_News_Record_Content_Blocks_Image | _Cmp_News_Record_Content_Blocks_LinkList | _Cmp_News_Record_Content_Blocks_RichText | _Cmp_News_Record_Content_Blocks_Spacer | _Cmp_News_Record_Content_Blocks_Table | _Cmp_News_Record_Content_Blocks_Title;

export type _Cmp_News_Record_Content_Blocks_Accordion = {
  __typename?: '_cmp_news_record_content_blocks_accordion';
  accordion?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion';
  content: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['output'];
  title: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content = _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_News | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table;

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_anchor';
  anchor?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_codeSnippet';
  codeSnippet?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_faqList';
  faqList?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_image';
  image?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content>;
};

export enum _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_image_content';
  borderColor: _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList';
  linkList?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_content';
  linkList: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_linkList';
  link: _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External | _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal;

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external';
  external?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_news';
  news?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_News_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_News_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_news_content';
  title: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content>;
};

export enum _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_richText_content';
  backgroundColor?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table';
  table?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_cellWidth';
  content: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_content';
  cellWidth: _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText;

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_header_content_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_rows';
  content: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText;

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_rows_content_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table_rows_content_richText_content';
  text: I18n_RichText;
};

export enum _Cmp_News_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmp_News_Record_Content_Blocks_Accordion_Content = {
  __typename?: '_cmp_news_record_content_blocks_accordion_content';
  accordion: Array<_Cmp_News_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: Maybe<_Cmp_News_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmp_News_Record_Content_Blocks_Anchor = {
  __typename?: '_cmp_news_record_content_blocks_anchor';
  anchor?: Maybe<_Cmp_News_Record_Content_Blocks_Anchor_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Anchor_Content = {
  __typename?: '_cmp_news_record_content_blocks_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_CodeSnippet = {
  __typename?: '_cmp_news_record_content_blocks_codeSnippet';
  codeSnippet?: Maybe<_Cmp_News_Record_Content_Blocks_CodeSnippet_Content>;
};

export type _Cmp_News_Record_Content_Blocks_CodeSnippet_Content = {
  __typename?: '_cmp_news_record_content_blocks_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_CtaButton = {
  __typename?: '_cmp_news_record_content_blocks_ctaButton';
  ctaButton?: Maybe<_Cmp_News_Record_Content_Blocks_CtaButton_Content>;
};

export type _Cmp_News_Record_Content_Blocks_CtaButton_Content = {
  __typename?: '_cmp_news_record_content_blocks_ctaButton_content';
  buttonLabel: I18n_String;
  buttonLink: LinkMandatory;
  position: _Cmp_News_Record_Content_Blocks_CtaButton_Position;
};

export enum _Cmp_News_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmp_News_Record_Content_Blocks_CustomQuote = {
  __typename?: '_cmp_news_record_content_blocks_customQuote';
  customQuote?: Maybe<_Cmp_News_Record_Content_Blocks_CustomQuote_Content>;
};

export type _Cmp_News_Record_Content_Blocks_CustomQuote_Content = {
  __typename?: '_cmp_news_record_content_blocks_customQuote_content';
  author: Scalars['String']['output'];
  quote: I18n_String;
  source: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_Embed = {
  __typename?: '_cmp_news_record_content_blocks_embed';
  embed?: Maybe<_Cmp_News_Record_Content_Blocks_Embed_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Content = {
  __typename?: '_cmp_news_record_content_blocks_embed_content';
  embedCode?: Maybe<I18n_String>;
  type: _Cmp_News_Record_Content_Blocks_Embed_Type;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type = _Cmp_News_Record_Content_Blocks_Embed_Type_Other | _Cmp_News_Record_Content_Blocks_Embed_Type_VimeoVideo | _Cmp_News_Record_Content_Blocks_Embed_Type_YouTubeVideo;

export type _Cmp_News_Record_Content_Blocks_Embed_Type_Other = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_other';
  other?: Maybe<_Cmp_News_Record_Content_Blocks_Embed_Type_Other_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type_Other_Content = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_other_content';
  height: I18n_Int;
  styleCustom: I18n_String;
  title: I18n_String;
  url: I18n_String;
  width: I18n_Int;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type_VimeoVideo = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_vimeoVideo';
  vimeoVideo?: Maybe<_Cmp_News_Record_Content_Blocks_Embed_Type_VimeoVideo_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type_VimeoVideo_Content = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_vimeoVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type_YouTubeVideo = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_News_Record_Content_Blocks_Embed_Type_YouTubeVideo_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Embed_Type_YouTubeVideo_Content = {
  __typename?: '_cmp_news_record_content_blocks_embed_type_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_News_Record_Content_Blocks_FaqList = {
  __typename?: '_cmp_news_record_content_blocks_faqList';
  faqList?: Maybe<_Cmp_News_Record_Content_Blocks_FaqList_Content>;
};

export type _Cmp_News_Record_Content_Blocks_FaqList_Content = {
  __typename?: '_cmp_news_record_content_blocks_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_News_Record_Content_Blocks_Image = {
  __typename?: '_cmp_news_record_content_blocks_image';
  image?: Maybe<_Cmp_News_Record_Content_Blocks_Image_Content>;
};

export enum _Cmp_News_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_News_Record_Content_Blocks_Image_Content = {
  __typename?: '_cmp_news_record_content_blocks_image_content';
  borderColor: _Cmp_News_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_News_Record_Content_Blocks_LinkList = {
  __typename?: '_cmp_news_record_content_blocks_linkList';
  linkList?: Maybe<_Cmp_News_Record_Content_Blocks_LinkList_Content>;
};

export type _Cmp_News_Record_Content_Blocks_LinkList_Content = {
  __typename?: '_cmp_news_record_content_blocks_linkList_content';
  linkList: Array<_Cmp_News_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmp_News_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList = {
  __typename?: '_cmp_news_record_content_blocks_linkList_linkList';
  link: _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link = _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External | _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal;

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_external';
  external?: Maybe<_Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_News_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_News_Record_Content_Blocks_RichText = {
  __typename?: '_cmp_news_record_content_blocks_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_RichText_Content>;
};

export enum _Cmp_News_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_News_Record_Content_Blocks_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_richText_content';
  backgroundColor?: Maybe<_Cmp_News_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_News_Record_Content_Blocks_Spacer = {
  __typename?: '_cmp_news_record_content_blocks_spacer';
  spacer?: Maybe<_Cmp_News_Record_Content_Blocks_Spacer_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Spacer_Content = {
  __typename?: '_cmp_news_record_content_blocks_spacer_content';
  height: Scalars['Int']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Table = {
  __typename?: '_cmp_news_record_content_blocks_table';
  table?: Maybe<_Cmp_News_Record_Content_Blocks_Table_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Table_CellWidth = {
  __typename?: '_cmp_news_record_content_blocks_table_cellWidth';
  content: Array<_Cmp_News_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Table_CellWidth_Content = {
  __typename?: '_cmp_news_record_content_blocks_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Table_Content = {
  __typename?: '_cmp_news_record_content_blocks_table_content';
  cellWidth: _Cmp_News_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_News_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmp_News_Record_Content_Blocks_Table_Rows>;
};

export type _Cmp_News_Record_Content_Blocks_Table_Header = {
  __typename?: '_cmp_news_record_content_blocks_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_News_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Table_Header_Content = _Cmp_News_Record_Content_Blocks_Table_Header_Content_RichText;

export type _Cmp_News_Record_Content_Blocks_Table_Header_Content_RichText = {
  __typename?: '_cmp_news_record_content_blocks_table_header_content_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_Table_Header_Content_RichText_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_News_Record_Content_Blocks_Table_Rows = {
  __typename?: '_cmp_news_record_content_blocks_table_rows';
  content: Array<_Cmp_News_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_News_Record_Content_Blocks_Table_Rows_Content = _Cmp_News_Record_Content_Blocks_Table_Rows_Content_RichText;

export type _Cmp_News_Record_Content_Blocks_Table_Rows_Content_RichText = {
  __typename?: '_cmp_news_record_content_blocks_table_rows_content_richText';
  richText?: Maybe<_Cmp_News_Record_Content_Blocks_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_news_record_content_blocks_table_rows_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_News_Record_Content_Blocks_Title = {
  __typename?: '_cmp_news_record_content_blocks_title';
  title?: Maybe<_Cmp_News_Record_Content_Blocks_Title_Content>;
};

export type _Cmp_News_Record_Content_Blocks_Title_Content = {
  __typename?: '_cmp_news_record_content_blocks_title_content';
  lead: I18n_String;
  title: I18n_String;
};

export type _Cmp_News_Record_Content_Video = _Cmp_News_Record_Content_Video_Youtube;

export type _Cmp_News_Record_Content_Video_Youtube = {
  __typename?: '_cmp_news_record_content_video_youtube';
  youtube?: Maybe<_Cmp_News_Record_Content_Video_Youtube_Content>;
};

export type _Cmp_News_Record_Content_Video_Youtube_Content = {
  __typename?: '_cmp_news_record_content_video_youtube_content';
  youtubeId: I18n_String;
};

export type _Cmp_News_Record_Meta = {
  __typename?: '_cmp_news_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  categories: Array<Maybe<Ref__CmpRef_CategoryNews>>;
  columnRightNew?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  presentOn: Array<Maybe<Ref__CmpRef_Page_Project>>;
  robotsMetaDirectives?: Maybe<_Cmp_News_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export type _Cmp_News_Record_Meta_ColumnRightNew = {
  __typename?: '_cmp_news_record_meta_columnRightNew';
  columnRight: Array<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['output'];
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight = _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button | _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Contact | _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Text | _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Title;

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button';
  button?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_buttonLink';
  link: _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External | _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal;

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_buttonLink_link_external';
  external?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_buttonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal';
  internal?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_button_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_contact';
  contact?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_contact_content';
  contact?: Maybe<Ref__CmpRef_Contact>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_text';
  text?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Text_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Text_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_text_content';
  infoBox: I18n_RichText;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_title';
  title?: Maybe<_Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Title_Content>;
};

export type _Cmp_News_Record_Meta_ColumnRightNew_ColumnRight_Title_Content = {
  __typename?: '_cmp_news_record_meta_columnRightNew_columnRight_title_content';
  title: I18n_String;
};

export type _Cmp_News_Record_Meta_PresentOn = _CmpRefCase_Page | _CmpRefCase_Project;

export enum _Cmp_News_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Page = {
  __typename?: '_cmp_page';
  list: _Cmp_PageConnection;
  read?: Maybe<_Cmp_Page_Record>;
};


export type _Cmp_PageListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Page>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Page>;
};


export type _Cmp_PageReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_PageConnection = {
  __typename?: '_cmp_pageConnection';
  nodes: Array<Array<_Cmp_Page_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Page_Record = {
  __typename?: '_cmp_page_record';
  content: _Cmp_Page_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Page_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content = {
  __typename?: '_cmp_page_record_content';
  blocks: Array<_Cmp_Page_Record_Content_Blocks>;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  leadText: I18n_String;
  teaserText?: Maybe<I18n_String>;
  teaserTitle: I18n_String;
  tileImage?: Maybe<_Cmp_Page_Record_Content_TileImage>;
  title: I18n_String;
  video?: Maybe<_Cmp_Page_Record_Content_Video>;
};

export type _Cmp_Page_Record_Content_Blocks = _Cmp_Page_Record_Content_Blocks_Accordion | _Cmp_Page_Record_Content_Blocks_Anchor | _Cmp_Page_Record_Content_Blocks_CodeSnippet | _Cmp_Page_Record_Content_Blocks_CtaButton | _Cmp_Page_Record_Content_Blocks_Custom | _Cmp_Page_Record_Content_Blocks_CustomQuote | _Cmp_Page_Record_Content_Blocks_Embed | _Cmp_Page_Record_Content_Blocks_FaqList | _Cmp_Page_Record_Content_Blocks_FilterViews | _Cmp_Page_Record_Content_Blocks_Hero | _Cmp_Page_Record_Content_Blocks_Image | _Cmp_Page_Record_Content_Blocks_LinkList | _Cmp_Page_Record_Content_Blocks_News | _Cmp_Page_Record_Content_Blocks_NewsGrid | _Cmp_Page_Record_Content_Blocks_OverviewResults | _Cmp_Page_Record_Content_Blocks_RichText | _Cmp_Page_Record_Content_Blocks_Slider | _Cmp_Page_Record_Content_Blocks_Spacer | _Cmp_Page_Record_Content_Blocks_Table | _Cmp_Page_Record_Content_Blocks_TeaserCarousel | _Cmp_Page_Record_Content_Blocks_TeaserSection | _Cmp_Page_Record_Content_Blocks_TeaserSlider | _Cmp_Page_Record_Content_Blocks_Title;

export type _Cmp_Page_Record_Content_Blocks_Accordion = {
  __typename?: '_cmp_page_record_content_blocks_accordion';
  accordion?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion';
  content: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['output'];
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content = _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_News | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table;

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_anchor';
  anchor?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_faqList';
  faqList?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_image';
  image?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content>;
};

export enum _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_image_content';
  borderColor: _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList';
  linkList?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_content';
  linkList: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_linkList';
  link: _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External | _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal;

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_news';
  news?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_News_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_News_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_news_content';
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content>;
};

export enum _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_richText_content';
  backgroundColor?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table';
  table?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_cellWidth';
  content: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_content';
  cellWidth: _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText;

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_header_content_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_rows';
  content: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText;

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_rows_content_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_accordion_content_table_rows_content_richText_content';
  text: I18n_RichText;
};

export enum _Cmp_Page_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmp_Page_Record_Content_Blocks_Accordion_Content = {
  __typename?: '_cmp_page_record_content_blocks_accordion_content';
  accordion: Array<_Cmp_Page_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: Maybe<_Cmp_Page_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmp_Page_Record_Content_Blocks_Anchor = {
  __typename?: '_cmp_page_record_content_blocks_anchor';
  anchor?: Maybe<_Cmp_Page_Record_Content_Blocks_Anchor_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Anchor_Content = {
  __typename?: '_cmp_page_record_content_blocks_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_CodeSnippet = {
  __typename?: '_cmp_page_record_content_blocks_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Page_Record_Content_Blocks_CodeSnippet_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_CodeSnippet_Content = {
  __typename?: '_cmp_page_record_content_blocks_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_CtaButton = {
  __typename?: '_cmp_page_record_content_blocks_ctaButton';
  ctaButton?: Maybe<_Cmp_Page_Record_Content_Blocks_CtaButton_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_CtaButton_Content = {
  __typename?: '_cmp_page_record_content_blocks_ctaButton_content';
  buttonLabel: I18n_String;
  buttonLink: LinkMandatory;
  position: _Cmp_Page_Record_Content_Blocks_CtaButton_Position;
};

export enum _Cmp_Page_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmp_Page_Record_Content_Blocks_Custom = {
  __typename?: '_cmp_page_record_content_blocks_custom';
  custom?: Maybe<_Cmp_Page_Record_Content_Blocks_Custom_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_CustomQuote = {
  __typename?: '_cmp_page_record_content_blocks_customQuote';
  customQuote?: Maybe<_Cmp_Page_Record_Content_Blocks_CustomQuote_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_CustomQuote_Content = {
  __typename?: '_cmp_page_record_content_blocks_customQuote_content';
  author: Scalars['String']['output'];
  quote: I18n_String;
  source: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Custom_Content = {
  __typename?: '_cmp_page_record_content_blocks_custom_content';
  contentType: Scalars['Boolean']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Embed = {
  __typename?: '_cmp_page_record_content_blocks_embed';
  embed?: Maybe<_Cmp_Page_Record_Content_Blocks_Embed_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Content = {
  __typename?: '_cmp_page_record_content_blocks_embed_content';
  embedCode?: Maybe<I18n_String>;
  type: _Cmp_Page_Record_Content_Blocks_Embed_Type;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type = _Cmp_Page_Record_Content_Blocks_Embed_Type_Other | _Cmp_Page_Record_Content_Blocks_Embed_Type_VimeoVideo | _Cmp_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo;

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_Other = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_other';
  other?: Maybe<_Cmp_Page_Record_Content_Blocks_Embed_Type_Other_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_Other_Content = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_other_content';
  height: I18n_Int;
  styleCustom: I18n_String;
  title: I18n_String;
  url: I18n_String;
  width: I18n_Int;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_VimeoVideo = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_vimeoVideo';
  vimeoVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_Embed_Type_VimeoVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_VimeoVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_vimeoVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_embed_type_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_FaqList = {
  __typename?: '_cmp_page_record_content_blocks_faqList';
  faqList?: Maybe<_Cmp_Page_Record_Content_Blocks_FaqList_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FaqList_Content = {
  __typename?: '_cmp_page_record_content_blocks_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews = {
  __typename?: '_cmp_page_record_content_blocks_filterViews';
  filterViews?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_content';
  contentTypeUnion: _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion;
  text: I18n_String;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion = _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects | _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events | _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia | _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News | _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects | _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications;

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_bridgeProjects';
  bridgeProjects?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_bridgeProjects_content';
  description: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_events';
  events?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_events_content';
  description: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_multimedia';
  multimedia?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_multimedia_content';
  description: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_news';
  news?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_news_content';
  categoriesNews: Array<Maybe<Ref__CmpRef_CategoryNews>>;
  filter?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter>;
  hideFilter: Scalars['Boolean']['output'];
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter = _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter;

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_news_filter_monthFilter';
  monthFilter?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_news_filter_monthFilter_content';
  order: _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Order;
  year: Scalars['Int']['output'];
};

export enum _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Order {
  /** Ascending */
  Ascending = 'ASCENDING',
  /** Descending */
  Descending = 'DESCENDING'
}

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_projects';
  projects?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_projects_content';
  description: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_publications';
  publications?: Maybe<_Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications_Content = {
  __typename?: '_cmp_page_record_content_blocks_filterViews_contentTypeUnion_publications_content';
  description: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Hero = {
  __typename?: '_cmp_page_record_content_blocks_hero';
  hero?: Maybe<_Cmp_Page_Record_Content_Blocks_Hero_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Content = {
  __typename?: '_cmp_page_record_content_blocks_hero_content';
  image?: Maybe<Ref__CmpRef_Media>;
  link?: Maybe<_Cmp_Page_Record_Content_Blocks_Hero_Link>;
  richText: I18n_RichText;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Link = {
  __typename?: '_cmp_page_record_content_blocks_hero_link';
  linkList: Array<_Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList>;
  listType: _Cmp_Page_Record_Content_Blocks_Hero_Link_ListType;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList = {
  __typename?: '_cmp_page_record_content_blocks_hero_link_linkList';
  link: _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link = _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External | _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal;

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External = {
  __typename?: '_cmp_page_record_content_blocks_hero_link_linkList_link_external';
  external?: Maybe<_Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Content = {
  __typename?: '_cmp_page_record_content_blocks_hero_link_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Target>;
};

export enum _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal = {
  __typename?: '_cmp_page_record_content_blocks_hero_link_linkList_link_internal';
  internal?: Maybe<_Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_page_record_content_blocks_hero_link_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Page_Record_Content_Blocks_Hero_Link_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Page_Record_Content_Blocks_Image = {
  __typename?: '_cmp_page_record_content_blocks_image';
  image?: Maybe<_Cmp_Page_Record_Content_Blocks_Image_Content>;
};

export enum _Cmp_Page_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Page_Record_Content_Blocks_Image_Content = {
  __typename?: '_cmp_page_record_content_blocks_image_content';
  borderColor: _Cmp_Page_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_LinkList = {
  __typename?: '_cmp_page_record_content_blocks_linkList';
  linkList?: Maybe<_Cmp_Page_Record_Content_Blocks_LinkList_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_LinkList_Content = {
  __typename?: '_cmp_page_record_content_blocks_linkList_content';
  linkList: Array<_Cmp_Page_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmp_Page_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList = {
  __typename?: '_cmp_page_record_content_blocks_linkList_linkList';
  link: _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link = _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External | _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal;

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_page_record_content_blocks_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_page_record_content_blocks_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_page_record_content_blocks_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_page_record_content_blocks_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Page_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Page_Record_Content_Blocks_News = {
  __typename?: '_cmp_page_record_content_blocks_news';
  news?: Maybe<_Cmp_Page_Record_Content_Blocks_News_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid';
  newsGrid?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<LinkOptional>;
  gridSlots: Array<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots>;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots';
  slotType: _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType = _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber | _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference | _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard | _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream;

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_disturber';
  disturber?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_disturber_content';
  displayGradient: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  link?: Maybe<LinkOptional>;
  text: I18n_String;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_reference';
  reference?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_reference_content';
  reference?: Maybe<Ref__CmpRef_News>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard';
  soMeCard?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType = _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed | _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost | _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet;

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_elfsightEmbed';
  elfsightEmbed?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_elfsightEmbed_content';
  embedCode: I18n_String;
  previewTitle: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_linkedInPost';
  linkedInPost?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_linkedInPost_content';
  embedCode: I18n_String;
  previewTitle: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_tweet';
  tweet?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_cardType_tweet_content';
  previewTitle: Scalars['String']['output'];
  tweetUrl: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_soMeCard_content';
  cardType: _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_stream';
  stream?: Maybe<_Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Content = {
  __typename?: '_cmp_page_record_content_blocks_newsGrid_gridSlots_slotType_stream_content';
  stream: _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Stream;
};

export enum _Cmp_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Stream {
  /** News */
  News = 'news'
}

export type _Cmp_Page_Record_Content_Blocks_News_Content = {
  __typename?: '_cmp_page_record_content_blocks_news_content';
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults';
  overviewResults?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Content = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_content';
  disableFilter: Scalars['Boolean']['output'];
  results: Array<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results>;
  text: I18n_String;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results = _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference | _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video;

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_reference';
  reference?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference_Content = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_reference_content';
  ref?: Maybe<Ref__CmpRef_News_Publication_Event>;
  text: I18n_String;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference_Ref = _CmpRefCase_Event | _CmpRefCase_News | _CmpRefCase_Publication;

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video';
  video?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Content = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video_content';
  image?: Maybe<Ref__CmpRef_Media>;
  module?: Maybe<Ref__CmpRef_Module>;
  text: I18n_String;
  title: I18n_String;
  type: _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type = _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo | _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo;

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video_type_vimeoVideo';
  vimeoVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video_type_vimeoVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video_type_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_overviewResults_results_video_type_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_RichText = {
  __typename?: '_cmp_page_record_content_blocks_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_RichText_Content>;
};

export enum _Cmp_Page_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Page_Record_Content_Blocks_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_richText_content';
  backgroundColor?: Maybe<_Cmp_Page_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Page_Record_Content_Blocks_Slider = {
  __typename?: '_cmp_page_record_content_blocks_slider';
  slider?: Maybe<_Cmp_Page_Record_Content_Blocks_Slider_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Slider_Content = {
  __typename?: '_cmp_page_record_content_blocks_slider_content';
  slider: Array<_Cmp_Page_Record_Content_Blocks_Slider_Slider>;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Slider_Slider = _Cmp_Page_Record_Content_Blocks_Slider_Slider_Default | _Cmp_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo;

export type _Cmp_Page_Record_Content_Blocks_Slider_Slider_Default = {
  __typename?: '_cmp_page_record_content_blocks_slider_slider_default';
  default?: Maybe<_Cmp_Page_Record_Content_Blocks_Slider_Slider_Default_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Slider_Slider_Default_Content = {
  __typename?: '_cmp_page_record_content_blocks_slider_slider_default_content';
  image?: Maybe<Ref__CmpRef_Media>;
  text: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo = {
  __typename?: '_cmp_page_record_content_blocks_slider_slider_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_slider_slider_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_Spacer = {
  __typename?: '_cmp_page_record_content_blocks_spacer';
  spacer?: Maybe<_Cmp_Page_Record_Content_Blocks_Spacer_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Spacer_Content = {
  __typename?: '_cmp_page_record_content_blocks_spacer_content';
  height: Scalars['Int']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Table = {
  __typename?: '_cmp_page_record_content_blocks_table';
  table?: Maybe<_Cmp_Page_Record_Content_Blocks_Table_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_CellWidth = {
  __typename?: '_cmp_page_record_content_blocks_table_cellWidth';
  content: Array<_Cmp_Page_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_CellWidth_Content = {
  __typename?: '_cmp_page_record_content_blocks_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Table_Content = {
  __typename?: '_cmp_page_record_content_blocks_table_content';
  cellWidth: _Cmp_Page_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Page_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmp_Page_Record_Content_Blocks_Table_Rows>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_Header = {
  __typename?: '_cmp_page_record_content_blocks_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Page_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_Header_Content = _Cmp_Page_Record_Content_Blocks_Table_Header_Content_RichText;

export type _Cmp_Page_Record_Content_Blocks_Table_Header_Content_RichText = {
  __typename?: '_cmp_page_record_content_blocks_table_header_content_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Page_Record_Content_Blocks_Table_Rows = {
  __typename?: '_cmp_page_record_content_blocks_table_rows';
  content: Array<_Cmp_Page_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_Table_Rows_Content = _Cmp_Page_Record_Content_Blocks_Table_Rows_Content_RichText;

export type _Cmp_Page_Record_Content_Blocks_Table_Rows_Content_RichText = {
  __typename?: '_cmp_page_record_content_blocks_table_rows_content_richText';
  richText?: Maybe<_Cmp_Page_Record_Content_Blocks_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_page_record_content_blocks_table_rows_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserCarousel = {
  __typename?: '_cmp_page_record_content_blocks_teaserCarousel';
  teaserCarousel?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserCarousel_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserCarousel_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserCarousel_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<LinkOptional>;
  slider: Array<_Cmp_Page_Record_Content_Blocks_TeaserCarousel_Slider>;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserCarousel_Slider = {
  __typename?: '_cmp_page_record_content_blocks_teaserCarousel_slider';
  ref?: Maybe<Ref__CmpRef_News_Project_Event_Publication>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserCarousel_Slider_Ref = _CmpRefCase_Event | _CmpRefCase_News | _CmpRefCase_Project | _CmpRefCase_Publication;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection';
  teaserSection?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<LinkOptional>;
  grid?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Grid>;
  teaser: _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser;
  text: I18n_String;
  title: I18n_String;
};

export enum _Cmp_Page_Record_Content_Blocks_TeaserSection_Grid {
  /** 2-spaltiges Layout */
  Cols_2 = 'cols_2',
  /** 3-spaltiges Layout */
  Cols_3 = 'cols_3'
}

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser = _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_auto';
  auto?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_auto_content';
  cellNumber: Scalars['Int']['output'];
  filterCategory?: Maybe<Ref__CmpRef_CategoryNews>;
  filterDate?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_FilterDate>;
};

export enum _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_FilterDate {
  /** after */
  After = 'after',
  /** before */
  Before = 'before'
}

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual';
  manual?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_content';
  list: Array<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List = _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_disturber';
  disturber?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Content>;
};

export enum _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_disturber_content';
  backgroundColor: _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_BackgroundColor;
  image?: Maybe<Ref__CmpRef_Media>;
  link?: Maybe<LinkOptional>;
  text: I18n_RichText;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_reference';
  reference?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_reference_content';
  ref?: Maybe<Ref__CmpRef_Page_News_Event_Publication>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference_Ref = _CmpRefCase_Event | _CmpRefCase_News | _CmpRefCase_Page | _CmpRefCase_Publication;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard';
  soMeCard?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType = _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_elfsightEmbed';
  elfsightEmbed?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_elfsightEmbed_content';
  embedCode: I18n_String;
  previewTitle: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_linkedInPost';
  linkedInPost?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_linkedInPost_content';
  embedCode: I18n_String;
  previewTitle: Scalars['String']['output'];
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_tweet';
  tweet?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_cardType_tweet_content';
  previewTitle: Scalars['String']['output'];
  tweetUrl: I18n_String;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_soMeCard_content';
  cardType: _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video';
  video?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_content';
  image?: Maybe<Ref__CmpRef_Media>;
  title: I18n_String;
  video: _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video = _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed';
  embed?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_content';
  embedCode?: Maybe<I18n_String>;
  type: _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type = _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo | _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo;

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_other';
  other?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_other_content';
  height: I18n_Int;
  styleCustom: I18n_String;
  title: I18n_String;
  url: I18n_String;
  width: I18n_Int;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_vimeoVideo';
  vimeoVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_vimeoVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_youTubeVideo';
  youTubeVideo?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSection_teaser_manual_list_video_video_embed_type_youTubeVideo_content';
  videoID: I18n_Id;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSlider = {
  __typename?: '_cmp_page_record_content_blocks_teaserSlider';
  teaserSlider?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSlider_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_TeaserSlider_Content = {
  __typename?: '_cmp_page_record_content_blocks_teaserSlider_content';
  contentType: _Cmp_Page_Record_Content_Blocks_TeaserSlider_ContentType;
  filterCriteriaCategories: Array<Maybe<Ref__CmpRef_CategoryNews_Module>>;
  filterCriteriaTags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export enum _Cmp_Page_Record_Content_Blocks_TeaserSlider_ContentType {
  /** News */
  News = 'news',
  /** Project */
  Project = 'project'
}

export type _Cmp_Page_Record_Content_Blocks_TeaserSlider_FilterCriteriaCategories = _CmpRefCase_CategoryNews | _CmpRefCase_Module;

export type _Cmp_Page_Record_Content_Blocks_Title = {
  __typename?: '_cmp_page_record_content_blocks_title';
  title?: Maybe<_Cmp_Page_Record_Content_Blocks_Title_Content>;
};

export type _Cmp_Page_Record_Content_Blocks_Title_Content = {
  __typename?: '_cmp_page_record_content_blocks_title_content';
  lead: I18n_String;
  title: I18n_String;
};

export type _Cmp_Page_Record_Content_TileImage = {
  __typename?: '_cmp_page_record_content_tileImage';
  tileImage?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmp_Page_Record_Content_Video = _Cmp_Page_Record_Content_Video_Youtube;

export type _Cmp_Page_Record_Content_Video_Youtube = {
  __typename?: '_cmp_page_record_content_video_youtube';
  youtube?: Maybe<_Cmp_Page_Record_Content_Video_Youtube_Content>;
};

export type _Cmp_Page_Record_Content_Video_Youtube_Content = {
  __typename?: '_cmp_page_record_content_video_youtube_content';
  youtubeId: I18n_String;
};

export type _Cmp_Page_Record_Meta = {
  __typename?: '_cmp_page_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  columnRightNew?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Page_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew = {
  __typename?: '_cmp_page_record_meta_columnRightNew';
  columnRight: Array<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['output'];
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight = _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button | _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact | _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Text | _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Title;

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button';
  button?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_buttonLink';
  link: _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External | _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal;

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_buttonLink_link_external';
  external?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_buttonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal';
  internal?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_button_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_contact';
  contact?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_contact_content';
  contact?: Maybe<Ref__CmpRef_Contact>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_text';
  text?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Text_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Text_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_text_content';
  infoBox: I18n_RichText;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_title';
  title?: Maybe<_Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Title_Content>;
};

export type _Cmp_Page_Record_Meta_ColumnRightNew_ColumnRight_Title_Content = {
  __typename?: '_cmp_page_record_meta_columnRightNew_columnRight_title_content';
  title: I18n_String;
};

export enum _Cmp_Page_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Project = {
  __typename?: '_cmp_project';
  list: _Cmp_ProjectConnection;
  read?: Maybe<_Cmp_Project_Record>;
};


export type _Cmp_ProjectListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Project>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Project>;
};


export type _Cmp_ProjectReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_ProjectConnection = {
  __typename?: '_cmp_projectConnection';
  nodes: Array<Array<_Cmp_Project_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Project_Record = {
  __typename?: '_cmp_project_record';
  content: _Cmp_Project_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Project_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Project_Record_Content = {
  __typename?: '_cmp_project_record_content';
  blocks: Array<_Cmp_Project_Record_Content_Blocks>;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  leadText: I18n_String;
  module?: Maybe<Ref__CmpRef_Module>;
  projectManagement: Scalars['String']['output'];
  projectNumber?: Maybe<Scalars['String']['output']>;
  projectResponsibles: I18n_RichText;
  shortLeadText: I18n_String;
  status: _Cmp_Project_Record_Content_Status;
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks = _Cmp_Project_Record_Content_Blocks_Accordion | _Cmp_Project_Record_Content_Blocks_Anchor | _Cmp_Project_Record_Content_Blocks_CodeSnippet | _Cmp_Project_Record_Content_Blocks_CtaButton | _Cmp_Project_Record_Content_Blocks_CustomQuote | _Cmp_Project_Record_Content_Blocks_Image | _Cmp_Project_Record_Content_Blocks_LinkList | _Cmp_Project_Record_Content_Blocks_News | _Cmp_Project_Record_Content_Blocks_RichText | _Cmp_Project_Record_Content_Blocks_Spacer | _Cmp_Project_Record_Content_Blocks_Table | _Cmp_Project_Record_Content_Blocks_Title;

export type _Cmp_Project_Record_Content_Blocks_Accordion = {
  __typename?: '_cmp_project_record_content_blocks_accordion';
  accordion?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion';
  content: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['output'];
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content = _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_News | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table;

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_anchor';
  anchor?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_faqList';
  faqList?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_faqList_content';
  faqList: Array<Maybe<Ref__CmpRef_Faq>>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_image';
  image?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content>;
};

export enum _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_image_content';
  borderColor: _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList';
  linkList?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_content';
  linkList: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_linkList';
  link: _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External | _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal;

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_news';
  news?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_News_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_News_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_news_content';
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content>;
};

export enum _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_richText_content';
  backgroundColor?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table';
  table?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_cellWidth';
  content: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_content';
  cellWidth: _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText;

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_header_content_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_rows';
  content: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText;

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_rows_content_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_accordion_content_table_rows_content_richText_content';
  text: I18n_RichText;
};

export enum _Cmp_Project_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmp_Project_Record_Content_Blocks_Accordion_Content = {
  __typename?: '_cmp_project_record_content_blocks_accordion_content';
  accordion: Array<_Cmp_Project_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: Maybe<_Cmp_Project_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmp_Project_Record_Content_Blocks_Anchor = {
  __typename?: '_cmp_project_record_content_blocks_anchor';
  anchor?: Maybe<_Cmp_Project_Record_Content_Blocks_Anchor_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Anchor_Content = {
  __typename?: '_cmp_project_record_content_blocks_anchor_content';
  anchorName: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_CodeSnippet = {
  __typename?: '_cmp_project_record_content_blocks_codeSnippet';
  codeSnippet?: Maybe<_Cmp_Project_Record_Content_Blocks_CodeSnippet_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_CodeSnippet_Content = {
  __typename?: '_cmp_project_record_content_blocks_codeSnippet_content';
  size?: Maybe<Size>;
  snippet: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_CtaButton = {
  __typename?: '_cmp_project_record_content_blocks_ctaButton';
  ctaButton?: Maybe<_Cmp_Project_Record_Content_Blocks_CtaButton_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_CtaButton_Content = {
  __typename?: '_cmp_project_record_content_blocks_ctaButton_content';
  buttonLabel: I18n_String;
  buttonLink: LinkMandatory;
  position: _Cmp_Project_Record_Content_Blocks_CtaButton_Position;
};

export enum _Cmp_Project_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmp_Project_Record_Content_Blocks_CustomQuote = {
  __typename?: '_cmp_project_record_content_blocks_customQuote';
  customQuote?: Maybe<_Cmp_Project_Record_Content_Blocks_CustomQuote_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_CustomQuote_Content = {
  __typename?: '_cmp_project_record_content_blocks_customQuote_content';
  author: Scalars['String']['output'];
  quote: I18n_String;
  source: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_Image = {
  __typename?: '_cmp_project_record_content_blocks_image';
  image?: Maybe<_Cmp_Project_Record_Content_Blocks_Image_Content>;
};

export enum _Cmp_Project_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Project_Record_Content_Blocks_Image_Content = {
  __typename?: '_cmp_project_record_content_blocks_image_content';
  borderColor: _Cmp_Project_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String;
  hasBorder: Scalars['Boolean']['output'];
  image?: Maybe<Ref__CmpRef_Media>;
  isZoomable: Scalars['Boolean']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_LinkList = {
  __typename?: '_cmp_project_record_content_blocks_linkList';
  linkList?: Maybe<_Cmp_Project_Record_Content_Blocks_LinkList_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_LinkList_Content = {
  __typename?: '_cmp_project_record_content_blocks_linkList_content';
  linkList: Array<_Cmp_Project_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmp_Project_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList = {
  __typename?: '_cmp_project_record_content_blocks_linkList_linkList';
  link: _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link = _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External | _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal;

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  __typename?: '_cmp_project_record_content_blocks_linkList_linkList_link_external';
  external?: Maybe<_Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Content = {
  __typename?: '_cmp_project_record_content_blocks_linkList_linkList_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  __typename?: '_cmp_project_record_content_blocks_linkList_linkList_link_internal';
  internal?: Maybe<_Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal_Content = {
  __typename?: '_cmp_project_record_content_blocks_linkList_linkList_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export enum _Cmp_Project_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmp_Project_Record_Content_Blocks_News = {
  __typename?: '_cmp_project_record_content_blocks_news';
  news?: Maybe<_Cmp_Project_Record_Content_Blocks_News_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_News_Content = {
  __typename?: '_cmp_project_record_content_blocks_news_content';
  title: I18n_String;
};

export type _Cmp_Project_Record_Content_Blocks_RichText = {
  __typename?: '_cmp_project_record_content_blocks_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_RichText_Content>;
};

export enum _Cmp_Project_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmp_Project_Record_Content_Blocks_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_richText_content';
  backgroundColor?: Maybe<_Cmp_Project_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText;
};

export type _Cmp_Project_Record_Content_Blocks_Spacer = {
  __typename?: '_cmp_project_record_content_blocks_spacer';
  spacer?: Maybe<_Cmp_Project_Record_Content_Blocks_Spacer_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Spacer_Content = {
  __typename?: '_cmp_project_record_content_blocks_spacer_content';
  height: Scalars['Int']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Table = {
  __typename?: '_cmp_project_record_content_blocks_table';
  table?: Maybe<_Cmp_Project_Record_Content_Blocks_Table_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_CellWidth = {
  __typename?: '_cmp_project_record_content_blocks_table_cellWidth';
  content: Array<_Cmp_Project_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_CellWidth_Content = {
  __typename?: '_cmp_project_record_content_blocks_table_cellWidth_content';
  id: Scalars['ID']['output'];
  width: Scalars['Int']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Table_Content = {
  __typename?: '_cmp_project_record_content_blocks_table_content';
  cellWidth: _Cmp_Project_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['output'];
  disableMargin: Scalars['Boolean']['output'];
  header: _Cmp_Project_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmp_Project_Record_Content_Blocks_Table_Rows>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_Header = {
  __typename?: '_cmp_project_record_content_blocks_table_header';
  active: Scalars['Boolean']['output'];
  content: Array<_Cmp_Project_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_Header_Content = _Cmp_Project_Record_Content_Blocks_Table_Header_Content_RichText;

export type _Cmp_Project_Record_Content_Blocks_Table_Header_Content_RichText = {
  __typename?: '_cmp_project_record_content_blocks_table_header_content_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_Table_Header_Content_RichText_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_Header_Content_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_table_header_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Project_Record_Content_Blocks_Table_Rows = {
  __typename?: '_cmp_project_record_content_blocks_table_rows';
  content: Array<_Cmp_Project_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['output'];
};

export type _Cmp_Project_Record_Content_Blocks_Table_Rows_Content = _Cmp_Project_Record_Content_Blocks_Table_Rows_Content_RichText;

export type _Cmp_Project_Record_Content_Blocks_Table_Rows_Content_RichText = {
  __typename?: '_cmp_project_record_content_blocks_table_rows_content_richText';
  richText?: Maybe<_Cmp_Project_Record_Content_Blocks_Table_Rows_Content_RichText_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Table_Rows_Content_RichText_Content = {
  __typename?: '_cmp_project_record_content_blocks_table_rows_content_richText_content';
  text: I18n_RichText;
};

export type _Cmp_Project_Record_Content_Blocks_Title = {
  __typename?: '_cmp_project_record_content_blocks_title';
  title?: Maybe<_Cmp_Project_Record_Content_Blocks_Title_Content>;
};

export type _Cmp_Project_Record_Content_Blocks_Title_Content = {
  __typename?: '_cmp_project_record_content_blocks_title_content';
  lead: I18n_String;
  title: I18n_String;
};

export enum _Cmp_Project_Record_Content_Status {
  /** completed */
  Completed = 'completed',
  /** ongoing */
  Ongoing = 'ongoing'
}

export type _Cmp_Project_Record_Meta = {
  __typename?: '_cmp_project_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  columnRightNew?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Project_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew = {
  __typename?: '_cmp_project_record_meta_columnRightNew';
  columnRight: Array<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['output'];
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight = _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button | _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact | _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Text | _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Title;

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button';
  button?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_buttonLink';
  link: _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External | _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal;

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_buttonLink_link_external';
  external?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_buttonLink_link_external_content';
  href: I18n_String;
  nofollow: Scalars['Boolean']['output'];
  target?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal';
  internal?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_buttonLink_link_internal_content';
  fragment?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Ref__CmpRef_Page_News_Event_Media_Multimedia_Project>;
  queryParams?: Maybe<Scalars['String']['output']>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_button_content';
  buttonLabel: I18n_String;
  buttonLink?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_contact';
  contact?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_contact_content';
  contact?: Maybe<Ref__CmpRef_Contact>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_text';
  text?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Text_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Text_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_text_content';
  infoBox: I18n_RichText;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_title';
  title?: Maybe<_Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Title_Content>;
};

export type _Cmp_Project_Record_Meta_ColumnRightNew_ColumnRight_Title_Content = {
  __typename?: '_cmp_project_record_meta_columnRightNew_columnRight_title_content';
  title: I18n_String;
};

export enum _Cmp_Project_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Publication = {
  __typename?: '_cmp_publication';
  list: _Cmp_PublicationConnection;
  read?: Maybe<_Cmp_Publication_Record>;
};


export type _Cmp_PublicationListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Publication>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Publication>;
};


export type _Cmp_PublicationReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_PublicationConnection = {
  __typename?: '_cmp_publicationConnection';
  nodes: Array<Array<_Cmp_Publication_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_PublicationType = {
  __typename?: '_cmp_publicationType';
  list: _Cmp_PublicationTypeConnection;
  read?: Maybe<_Cmp_PublicationType_Record>;
};


export type _Cmp_PublicationTypeListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_PublicationType>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_PublicationType>;
};


export type _Cmp_PublicationTypeReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_PublicationTypeConnection = {
  __typename?: '_cmp_publicationTypeConnection';
  nodes: Array<Array<_Cmp_PublicationType_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_PublicationType_Record = {
  __typename?: '_cmp_publicationType_record';
  content: _Cmp_PublicationType_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_PublicationType_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_PublicationType_Record_Content = {
  __typename?: '_cmp_publicationType_record_content';
  category: I18n_String;
  order: Scalars['Int']['output'];
};

export type _Cmp_PublicationType_Record_Meta = {
  __typename?: '_cmp_publicationType_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Publication_Record = {
  __typename?: '_cmp_publication_record';
  content: _Cmp_Publication_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Publication_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Publication_Record_Content = {
  __typename?: '_cmp_publication_record_content';
  bibliography: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  download?: Maybe<Ref__CmpRef_Media>;
  image?: Maybe<Ref__CmpRef_Media>;
  isImageZoomable?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<LinkOptional>;
  module?: Maybe<Ref__CmpRef_Module>;
  publicationType?: Maybe<Ref__CmpRef_PublicationType>;
  publisher: Scalars['String']['output'];
  teaserText?: Maybe<I18n_String>;
  tileImage?: Maybe<_Cmp_Publication_Record_Content_TileImage>;
  title: I18n_String;
};

export type _Cmp_Publication_Record_Content_TileImage = {
  __typename?: '_cmp_publication_record_content_tileImage';
  tileImage?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmp_Publication_Record_Meta = {
  __typename?: '_cmp_publication_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  description: I18n_String;
  migrationsUrl: I18n_String;
  robotsMetaDirectives?: Maybe<_Cmp_Publication_Record_Meta_RobotsMetaDirectives>;
  shareImage?: Maybe<Ref__CmpRef_Media>;
  tags: Array<Maybe<Ref__CmpRef_Tag>>;
  title: I18n_String;
};

export enum _Cmp_Publication_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmp_Tag = {
  __typename?: '_cmp_tag';
  list: _Cmp_TagConnection;
  read?: Maybe<_Cmp_Tag_Record>;
};


export type _Cmp_TagListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Tag>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Tag>;
};


export type _Cmp_TagReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_TagConnection = {
  __typename?: '_cmp_tagConnection';
  nodes: Array<Array<_Cmp_Tag_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Tag_Record = {
  __typename?: '_cmp_tag_record';
  content: _Cmp_Tag_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  meta: _Cmp_Tag_Record_Meta;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Tag_Record_Content = {
  __typename?: '_cmp_tag_record_content';
  tag: I18n_String;
};

export type _Cmp_Tag_Record_Meta = {
  __typename?: '_cmp_tag_record_meta';
  allowMigration?: Maybe<Scalars['Boolean']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  migrationsUrl: I18n_String;
};

export type _Cmp_Website = {
  __typename?: '_cmp_website';
  list: _Cmp_WebsiteConnection;
  read?: Maybe<_Cmp_Website_Record>;
};


export type _Cmp_WebsiteListArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Filter_Website>;
  filterOperator?: InputMaybe<FilterOperator>;
  first?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Languages>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortOrder>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort_Website>;
};


export type _Cmp_WebsiteReadArgs = {
  id: Scalars['ID']['input'];
  peerID?: InputMaybe<Scalars['ID']['input']>;
  revision: Scalars['Int']['input'];
};

export type _Cmp_WebsiteConnection = {
  __typename?: '_cmp_websiteConnection';
  nodes: Array<Array<_Cmp_Website_Record>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type _Cmp_Website_Record = {
  __typename?: '_cmp_website_record';
  content: _Cmp_Website_Record_Content;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  dePublicationDate?: Maybe<Scalars['DateTime']['output']>;
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  disableSearch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isActiveI18n: I18n_Boolean;
  modifiedAt: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  publicationDate?: Maybe<Scalars['DateTime']['output']>;
  publicationState: PublicationState;
  revision: Scalars['Int']['output'];
  richTextReferences: Array<Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType>;
  shared: Scalars['Boolean']['output'];
  slugI18n: I18n_String;
  title: Scalars['String']['output'];
};

export type _Cmp_Website_Record_Content = {
  __typename?: '_cmp_website_record_content';
  callForProposalCategory?: Maybe<Ref__CmpRef_CategoryNews>;
  dataPortalBaseLink?: Maybe<I18n_String>;
  defaultFallbackImage?: Maybe<Ref__CmpRef_Media>;
  defaultMetaDescription: I18n_String;
  defaultMetaTitle: I18n_String;
  defaultShareImage?: Maybe<Ref__CmpRef_Media>;
  error404Page?: Maybe<Ref__CmpRef_Page>;
  favicon?: Maybe<Ref__CmpRef_Media>;
  footer?: Maybe<Ref__CmpRef_Footer>;
  googleApiKey: Scalars['String']['output'];
  googleApiKeyV4: Scalars['String']['output'];
  googleTagManagerKey: Scalars['String']['output'];
  headerLink?: Maybe<LinkOptional>;
  headerLinkText: I18n_String;
  homePage?: Maybe<Ref__CmpRef_Page>;
  isBridge?: Maybe<Scalars['Boolean']['output']>;
  navigator?: Maybe<Ref__CmpRef_Navigation>;
  newsCategory?: Maybe<Ref__CmpRef_CategoryNews>;
  nfpIdentifier: Scalars['String']['output'];
  pressReleaseCategory?: Maybe<Ref__CmpRef_CategoryNews>;
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  title: I18n_String;
  websiteLogo?: Maybe<Ref__CmpRef_Media>;
};

export type _Cmpi_BridgeProject = {
  __typename?: '_cmpi_bridgeProject';
  archive?: Maybe<_Cmp_BridgeProject_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_BridgeProject_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_BridgeProject_Record>;
  publish?: Maybe<_Cmp_BridgeProject_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_BridgeProject_Record;
};


export type _Cmpi_BridgeProjectArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_BridgeProject_Record_Create;
};


export type _Cmpi_BridgeProjectDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_BridgeProjectPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectUpdateArgs = {
  input: _Cmpi_BridgeProject_Record_Update;
};

export type _Cmpi_BridgeProjectInnovationField = {
  __typename?: '_cmpi_bridgeProjectInnovationField';
  archive?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_BridgeProjectInnovationField_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
  publish?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_BridgeProjectInnovationField_Record;
};


export type _Cmpi_BridgeProjectInnovationFieldArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInnovationFieldCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_BridgeProjectInnovationField_Record_Create;
};


export type _Cmpi_BridgeProjectInnovationFieldDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInnovationFieldMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_BridgeProjectInnovationFieldPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInnovationFieldUpdateArgs = {
  input: _Cmpi_BridgeProjectInnovationField_Record_Update;
};

export type _Cmpi_BridgeProjectInnovationField_Record_Content = {
  innovationField: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectInnovationField_Record_Create = {
  content: _Cmpi_BridgeProjectInnovationField_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectInnovationField_Record_Update = {
  content: _Cmpi_BridgeProjectInnovationField_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectInstitution = {
  __typename?: '_cmpi_bridgeProjectInstitution';
  archive?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_BridgeProjectInstitution_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
  publish?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_BridgeProjectInstitution_Record;
};


export type _Cmpi_BridgeProjectInstitutionArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInstitutionCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_BridgeProjectInstitution_Record_Create;
};


export type _Cmpi_BridgeProjectInstitutionDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInstitutionMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_BridgeProjectInstitutionPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectInstitutionUpdateArgs = {
  input: _Cmpi_BridgeProjectInstitution_Record_Update;
};

export type _Cmpi_BridgeProjectInstitution_Record_Content = {
  institution: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectInstitution_Record_Create = {
  content: _Cmpi_BridgeProjectInstitution_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectInstitution_Record_Update = {
  content: _Cmpi_BridgeProjectInstitution_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectType = {
  __typename?: '_cmpi_bridgeProjectType';
  archive?: Maybe<_Cmp_BridgeProjectType_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_BridgeProjectType_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_BridgeProjectType_Record>;
  publish?: Maybe<_Cmp_BridgeProjectType_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_BridgeProjectType_Record;
};


export type _Cmpi_BridgeProjectTypeArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectTypeCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_BridgeProjectType_Record_Create;
};


export type _Cmpi_BridgeProjectTypeDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectTypeMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_BridgeProjectTypePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_BridgeProjectTypeUpdateArgs = {
  input: _Cmpi_BridgeProjectType_Record_Update;
};

export type _Cmpi_BridgeProjectType_Record_Content = {
  type: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectType_Record_Create = {
  content: _Cmpi_BridgeProjectType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProjectType_Record_Update = {
  content: _Cmpi_BridgeProjectType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProject_Record_Content = {
  abstract: Scalars['String']['input'];
  coApplicant: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  innovationField?: InputMaybe<Ref_Input>;
  institution?: InputMaybe<Ref_Input>;
  keywords: Array<Scalars['String']['input']>;
  projectNumber: Scalars['String']['input'];
  projectType?: InputMaybe<Ref_Input>;
  responsibleApplicant: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProject_Record_Create = {
  content: _Cmpi_BridgeProject_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_BridgeProject_Record_Update = {
  content: _Cmpi_BridgeProject_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_CategoryNews = {
  __typename?: '_cmpi_categoryNews';
  archive?: Maybe<_Cmp_CategoryNews_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_CategoryNews_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_CategoryNews_Record>;
  publish?: Maybe<_Cmp_CategoryNews_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_CategoryNews_Record;
};


export type _Cmpi_CategoryNewsArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_CategoryNewsCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_CategoryNews_Record_Create;
};


export type _Cmpi_CategoryNewsDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_CategoryNewsMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_CategoryNewsPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_CategoryNewsUpdateArgs = {
  input: _Cmpi_CategoryNews_Record_Update;
};

export type _Cmpi_CategoryNews_Record_Content = {
  category: I18n_String_Input;
  categorySingular: I18n_String_Input;
  order: Scalars['Int']['input'];
};

export type _Cmpi_CategoryNews_Record_Create = {
  content: _Cmpi_CategoryNews_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_CategoryNews_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_CategoryNews_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_CategoryNews_Record_Update = {
  content: _Cmpi_CategoryNews_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_CategoryNews_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Contact = {
  __typename?: '_cmpi_contact';
  archive?: Maybe<_Cmp_Contact_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Contact_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Contact_Record>;
  publish?: Maybe<_Cmp_Contact_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Contact_Record;
};


export type _Cmpi_ContactArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ContactCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Contact_Record_Create;
};


export type _Cmpi_ContactDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ContactMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_ContactPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ContactUpdateArgs = {
  input: _Cmpi_Contact_Record_Update;
};

export type _Cmpi_Contact_Record_Content = {
  address?: InputMaybe<I18n_String_Input>;
  eMail?: InputMaybe<Scalars['String']['input']>;
  info?: InputMaybe<I18n_RichText_Input>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<I18n_String_Input>;
};

export type _Cmpi_Contact_Record_Create = {
  content: _Cmpi_Contact_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Contact_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Contact_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_Contact_Record_Update = {
  content: _Cmpi_Contact_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Contact_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Domain = {
  __typename?: '_cmpi_domain';
  archive?: Maybe<_Cmp_Domain_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Domain_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Domain_Record>;
  publish?: Maybe<_Cmp_Domain_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Domain_Record;
};


export type _Cmpi_DomainArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_DomainCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Domain_Record_Create;
};


export type _Cmpi_DomainDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_DomainMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_DomainPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_DomainUpdateArgs = {
  input: _Cmpi_Domain_Record_Update;
};

export type _Cmpi_Domain_Record_Content = {
  domains: Array<Scalars['String']['input']>;
  forwards: Array<_Cmpi_Domain_Record_Content_Forwards>;
  serve?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Domain_Record_Content_Forwards = {
  pattern: Scalars['String']['input'];
  regex: Scalars['Boolean']['input'];
  target: _Cmpi_Domain_Record_Content_Forwards_Target;
};

export type _Cmpi_Domain_Record_Content_Forwards_Target = {
  external?: InputMaybe<_Cmpi_Domain_Record_Content_Forwards_Target_External>;
  internal?: InputMaybe<_Cmpi_Domain_Record_Content_Forwards_Target_Internal>;
};

export type _Cmpi_Domain_Record_Content_Forwards_Target_External = {
  href: Scalars['String']['input'];
};

export type _Cmpi_Domain_Record_Content_Forwards_Target_Internal = {
  language?: InputMaybe<_Cmpi_Domain_Record_Content_Forwards_Target_Internal_Language>;
  link?: InputMaybe<Ref_Input>;
};

export enum _Cmpi_Domain_Record_Content_Forwards_Target_Internal_Language {
  /** de */
  De = 'de',
  /** en */
  En = 'en',
  /** fr */
  Fr = 'fr',
  /** it */
  It = 'it'
}

export type _Cmpi_Domain_Record_Create = {
  content: _Cmpi_Domain_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Domain_Record_Update = {
  content: _Cmpi_Domain_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Event = {
  __typename?: '_cmpi_event';
  archive?: Maybe<_Cmp_Event_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Event_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Event_Record>;
  publish?: Maybe<_Cmp_Event_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Event_Record;
};


export type _Cmpi_EventArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Event_Record_Create;
};


export type _Cmpi_EventDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_EventPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventUpdateArgs = {
  input: _Cmpi_Event_Record_Update;
};

export type _Cmpi_EventType = {
  __typename?: '_cmpi_eventType';
  archive?: Maybe<_Cmp_EventType_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_EventType_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_EventType_Record>;
  publish?: Maybe<_Cmp_EventType_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_EventType_Record;
};


export type _Cmpi_EventTypeArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventTypeCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_EventType_Record_Create;
};


export type _Cmpi_EventTypeDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventTypeMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_EventTypePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_EventTypeUpdateArgs = {
  input: _Cmpi_EventType_Record_Update;
};

export type _Cmpi_EventType_Record_Content = {
  order: Scalars['Int']['input'];
  type: I18n_String_Input;
};

export type _Cmpi_EventType_Record_Create = {
  content: _Cmpi_EventType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_EventType_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_EventType_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_EventType_Record_Update = {
  content: _Cmpi_EventType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_EventType_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Event_Record_Content = {
  address?: InputMaybe<Scalars['String']['input']>;
  blocks: Array<_Cmpi_Event_Record_Content_Blocks>;
  endDate: Scalars['DateTime']['input'];
  eventType?: InputMaybe<Ref_Input>;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  leadText: I18n_String_Input;
  module?: InputMaybe<Ref_Input>;
  organizer: I18n_String_Input;
  place: I18n_String_Input;
  placeShort: I18n_String_Input;
  program?: InputMaybe<Array<_Cmpi_Event_Record_Content_Program>>;
  registrationDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  registrationFormSnippet: I18n_String_Input;
  startDate: Scalars['DateTime']['input'];
  text: I18n_RichText_Input;
  tileImage?: InputMaybe<_Cmpi_Event_Record_Content_TileImage>;
  title: I18n_String_Input;
  venueURL?: InputMaybe<I18n_String_Input>;
};

export type _Cmpi_Event_Record_Content_Blocks = {
  accordion?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion>;
  anchor?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CodeSnippet>;
  ctaButton?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CtaButton>;
  customQuote?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CustomQuote>;
  image?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Image>;
  linkList?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_LinkList>;
  news?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_News>;
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_RichText>;
  spacer?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Spacer>;
  table?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Table>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion = {
  accordion: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion = {
  content: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['input'];
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content = {
  anchor?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet>;
  faqList?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList>;
  image?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image>;
  linkList?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList>;
  news?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_News>;
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText>;
  table?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  borderColor: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  linkList: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  link: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  cellWidth: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  content: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  content: Array<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText>;
};

export type _Cmpi_Event_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export enum _Cmpi_Event_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmpi_Event_Record_Content_Blocks_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Event_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Event_Record_Content_Blocks_CtaButton = {
  buttonLabel: I18n_String_Input;
  buttonLink: _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink;
  position: _Cmpi_Event_Record_Content_Blocks_CtaButton_Position;
};

export type _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink = {
  link: _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link;
};

export type _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal>;
};

export type _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Event_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Event_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmpi_Event_Record_Content_Blocks_CustomQuote = {
  author: Scalars['String']['input'];
  quote: I18n_String_Input;
  source: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Image = {
  borderColor: _Cmpi_Event_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Event_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Event_Record_Content_Blocks_LinkList = {
  linkList: Array<_Cmpi_Event_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmpi_Event_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList = {
  link: _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Event_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Event_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Event_Record_Content_Blocks_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Event_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Event_Record_Content_Blocks_Spacer = {
  height: Scalars['Int']['input'];
};

export type _Cmpi_Event_Record_Content_Blocks_Table = {
  cellWidth: _Cmpi_Event_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Event_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmpi_Event_Record_Content_Blocks_Table_Rows>;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_CellWidth = {
  content: Array<_Cmpi_Event_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Event_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Table_Header_Content_RichText>;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Rows = {
  content: Array<_Cmpi_Event_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Event_Record_Content_Blocks_Table_Rows_Content_RichText>;
};

export type _Cmpi_Event_Record_Content_Blocks_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Event_Record_Content_Program = {
  startEndTime: Scalars['String']['input'];
  subTitle: I18n_String_Input;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Content_TileImage = {
  tileImage?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Event_Record_Create = {
  content: _Cmpi_Event_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Event_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Event_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  columnRightNew?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Event_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew = {
  columnRight: Array<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['input'];
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight = {
  button?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button>;
  contact?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact>;
  text?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Text>;
  title?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Title>;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  link: _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal>;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  contact?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  infoBox: I18n_RichText_Input;
};

export type _Cmpi_Event_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  title: I18n_String_Input;
};

export enum _Cmpi_Event_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Event_Record_Update = {
  content: _Cmpi_Event_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Event_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Faq = {
  __typename?: '_cmpi_faq';
  archive?: Maybe<_Cmp_Faq_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Faq_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Faq_Record>;
  publish?: Maybe<_Cmp_Faq_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Faq_Record;
};


export type _Cmpi_FaqArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FaqCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Faq_Record_Create;
};


export type _Cmpi_FaqDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FaqMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_FaqPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FaqUpdateArgs = {
  input: _Cmpi_Faq_Record_Update;
};

export type _Cmpi_Faq_Record_Content = {
  anchorName: I18n_String_Input;
  answer: I18n_RichText_Input;
  question: I18n_String_Input;
};

export type _Cmpi_Faq_Record_Create = {
  content: _Cmpi_Faq_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Faq_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Faq_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
  tags?: InputMaybe<Array<InputMaybe<Ref_Input>>>;
};

export type _Cmpi_Faq_Record_Update = {
  content: _Cmpi_Faq_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Faq_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Footer = {
  __typename?: '_cmpi_footer';
  archive?: Maybe<_Cmp_Footer_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Footer_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Footer_Record>;
  publish?: Maybe<_Cmp_Footer_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Footer_Record;
};


export type _Cmpi_FooterArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FooterCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Footer_Record_Create;
};


export type _Cmpi_FooterDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FooterMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_FooterPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_FooterUpdateArgs = {
  input: _Cmpi_Footer_Record_Update;
};

export type _Cmpi_Footer_Record_Content = {
  additionalLogo?: InputMaybe<_Cmpi_Footer_Record_Content_AdditionalLogo>;
  copyright: I18n_String_Input;
  newsletterButtonLabel: I18n_String_Input;
  newsletterButtonLink?: InputMaybe<_Cmpi_Footer_Record_Content_NewsletterButtonLink>;
  section2: I18n_RichText_Input;
  section2Logo?: InputMaybe<_Cmpi_Footer_Record_Content_Section2Logo>;
  section2Title: I18n_String_Input;
  section3: I18n_RichText_Input;
  section3Title: I18n_String_Input;
  section4: I18n_RichText_Input;
  section4Title: I18n_String_Input;
  socialMedia: Array<_Cmpi_Footer_Record_Content_SocialMedia>;
  subMenu: _Cmpi_Footer_Record_Content_SubMenu;
};

export type _Cmpi_Footer_Record_Content_AdditionalLogo = {
  link?: InputMaybe<_Cmpi_Footer_Record_Content_AdditionalLogo_Link>;
  logo?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Footer_Record_Content_AdditionalLogo_Link = {
  link: _Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link;
};

export type _Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link = {
  external?: InputMaybe<_Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_Internal>;
};

export type _Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_External_Target>;
};

export enum _Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Footer_Record_Content_AdditionalLogo_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Footer_Record_Content_NewsletterButtonLink = {
  link: _Cmpi_Footer_Record_Content_NewsletterButtonLink_Link;
};

export type _Cmpi_Footer_Record_Content_NewsletterButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_Internal>;
};

export type _Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_External_Target>;
};

export enum _Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Footer_Record_Content_NewsletterButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Footer_Record_Content_Section2Logo = {
  link?: InputMaybe<_Cmpi_Footer_Record_Content_Section2Logo_Link>;
  logo?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Footer_Record_Content_Section2Logo_Link = {
  link: _Cmpi_Footer_Record_Content_Section2Logo_Link_Link;
};

export type _Cmpi_Footer_Record_Content_Section2Logo_Link_Link = {
  external?: InputMaybe<_Cmpi_Footer_Record_Content_Section2Logo_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Footer_Record_Content_Section2Logo_Link_Link_Internal>;
};

export type _Cmpi_Footer_Record_Content_Section2Logo_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Footer_Record_Content_Section2Logo_Link_Link_External_Target>;
};

export enum _Cmpi_Footer_Record_Content_Section2Logo_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Footer_Record_Content_Section2Logo_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Footer_Record_Content_SocialMedia = {
  link: I18n_String_Input;
  platform: _Cmpi_Footer_Record_Content_SocialMedia_Platform;
};

export enum _Cmpi_Footer_Record_Content_SocialMedia_Platform {
  /** Facebook */
  Facebook = 'facebook',
  /** Instagram */
  Instagram = 'instagram',
  /** LinkedIn */
  LinkedIn = 'linkedIn',
  /** Mastodon */
  Mastodon = 'mastodon',
  /** Twitter */
  Twitter = 'twitter',
  /** Xing */
  Xing = 'xing',
  /** Youtube */
  Youtube = 'youtube'
}

export type _Cmpi_Footer_Record_Content_SubMenu = {
  linkList: Array<_Cmpi_Footer_Record_Content_SubMenu_LinkList>;
};

export type _Cmpi_Footer_Record_Content_SubMenu_LinkList = {
  link: _Cmpi_Footer_Record_Content_SubMenu_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Footer_Record_Content_SubMenu_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_Internal>;
};

export type _Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_External_Target>;
};

export enum _Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Footer_Record_Content_SubMenu_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Footer_Record_Create = {
  content: _Cmpi_Footer_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Footer_Record_Update = {
  content: _Cmpi_Footer_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Media = {
  __typename?: '_cmpi_media';
  archive?: Maybe<_Cmp_Media_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Media_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Media_Record>;
  publish?: Maybe<_Cmp_Media_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Media_Record;
};


export type _Cmpi_MediaArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MediaCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Media_Record_Create;
};


export type _Cmpi_MediaDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MediaMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_MediaPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MediaUpdateArgs = {
  input: _Cmpi_Media_Record_Update;
};

export type _Cmpi_Media_Record_Content = {
  altText: I18n_String_Input;
  license: Scalars['String']['input'];
  media?: InputMaybe<I18n_Media_Input_Input>;
  source: Scalars['String']['input'];
  title: I18n_String_Input;
};

export type _Cmpi_Media_Record_Create = {
  content: _Cmpi_Media_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Media_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Media_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Media_Record_Meta_RobotsMetaDirectives>;
};

export enum _Cmpi_Media_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Media_Record_Update = {
  content: _Cmpi_Media_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Media_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Module = {
  __typename?: '_cmpi_module';
  archive?: Maybe<_Cmp_Module_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Module_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Module_Record>;
  publish?: Maybe<_Cmp_Module_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Module_Record;
};


export type _Cmpi_ModuleArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ModuleCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Module_Record_Create;
};


export type _Cmpi_ModuleDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ModuleMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_ModulePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ModuleUpdateArgs = {
  input: _Cmpi_Module_Record_Update;
};

export type _Cmpi_Module_Record_Content = {
  category: I18n_String_Input;
  order: Scalars['Int']['input'];
};

export type _Cmpi_Module_Record_Create = {
  content: _Cmpi_Module_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Module_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Module_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_Module_Record_Update = {
  content: _Cmpi_Module_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Module_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Multimedia = {
  __typename?: '_cmpi_multimedia';
  archive?: Maybe<_Cmp_Multimedia_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Multimedia_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Multimedia_Record>;
  publish?: Maybe<_Cmp_Multimedia_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Multimedia_Record;
};


export type _Cmpi_MultimediaArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MultimediaCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Multimedia_Record_Create;
};


export type _Cmpi_MultimediaDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MultimediaMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_MultimediaPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_MultimediaUpdateArgs = {
  input: _Cmpi_Multimedia_Record_Update;
};

export type _Cmpi_Multimedia_Record_Content = {
  content: _Cmpi_Multimedia_Record_Content_Content;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  leadText: I18n_String_Input;
  newsDate: Scalars['DateTime']['input'];
  shortLeadText: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Multimedia_Record_Content_Content = {
  gallery?: InputMaybe<_Cmpi_Multimedia_Record_Content_Content_Gallery>;
  video?: InputMaybe<_Cmpi_Multimedia_Record_Content_Content_Video>;
};

export type _Cmpi_Multimedia_Record_Content_Content_Gallery = {
  images: Array<InputMaybe<_Cmpi_Multimedia_Record_Content_Content_Gallery_Images>>;
};

export type _Cmpi_Multimedia_Record_Content_Content_Gallery_Images = {
  borderColor: _Cmpi_Multimedia_Record_Content_Content_Gallery_Images_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Multimedia_Record_Content_Content_Gallery_Images_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Multimedia_Record_Content_Content_Video = {
  video: _Cmpi_Multimedia_Record_Content_Content_Video_Video;
};

export type _Cmpi_Multimedia_Record_Content_Content_Video_Video = {
  type: _Cmpi_Multimedia_Record_Content_Content_Video_Video_Type;
};

export type _Cmpi_Multimedia_Record_Content_Content_Video_Video_Type = {
  vimeoVideo?: InputMaybe<_Cmpi_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo>;
  youTubeVideo?: InputMaybe<_Cmpi_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo>;
};

export type _Cmpi_Multimedia_Record_Content_Content_Video_Video_Type_VimeoVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Multimedia_Record_Content_Content_Video_Video_Type_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Multimedia_Record_Create = {
  content: _Cmpi_Multimedia_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Multimedia_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Multimedia_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  columnRightNew?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Multimedia_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew = {
  columnRight: Array<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['input'];
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight = {
  button?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button>;
  contact?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact>;
  text?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text>;
  title?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title>;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  link: _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal>;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  contact?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  infoBox: I18n_RichText_Input;
};

export type _Cmpi_Multimedia_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  title: I18n_String_Input;
};

export enum _Cmpi_Multimedia_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Multimedia_Record_Update = {
  content: _Cmpi_Multimedia_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Multimedia_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Navigation = {
  __typename?: '_cmpi_navigation';
  archive?: Maybe<_Cmp_Navigation_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Navigation_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Navigation_Record>;
  publish?: Maybe<_Cmp_Navigation_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Navigation_Record;
};


export type _Cmpi_NavigationArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NavigationCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Navigation_Record_Create;
};


export type _Cmpi_NavigationDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NavigationMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_NavigationPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NavigationUpdateArgs = {
  input: _Cmpi_Navigation_Record_Update;
};

export type _Cmpi_Navigation_Record_Content = {
  navigation: Array<_Cmpi_Navigation_Record_Content_Navigation>;
};

export type _Cmpi_Navigation_Record_Content_Navigation = {
  childContents: Array<InputMaybe<Ref_Input>>;
  children?: InputMaybe<Array<_Cmpi_Navigation_Record_Content_Navigation_Children>>;
  expand?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inactive?: InputMaybe<I18n_Boolean_Input>;
  label: Scalars['String']['input'];
  labelI18n: I18n_String_Input;
  layer?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Link>;
  parentForContentOfType?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_ParentForContentOfType>;
  refKey?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children = {
  childContents: Array<InputMaybe<Ref_Input>>;
  children?: InputMaybe<Array<_Cmpi_Navigation_Record_Content_Navigation_Children_Children>>;
  expand?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inactive?: InputMaybe<I18n_Boolean_Input>;
  label: Scalars['String']['input'];
  labelI18n: I18n_String_Input;
  layer?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Link>;
  parentForContentOfType?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_ParentForContentOfType>;
  refKey?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Children = {
  childContents: Array<InputMaybe<Ref_Input>>;
  expand?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inactive?: InputMaybe<I18n_Boolean_Input>;
  label: Scalars['String']['input'];
  labelI18n: I18n_String_Input;
  layer?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link>;
  parentForContentOfType?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Children_ParentForContentOfType>;
  refKey?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link = {
  link: _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link = {
  external?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Target>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Children_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_Children_Children_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Link = {
  link: _Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link = {
  external?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_Internal>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_External_Target>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Navigation_Record_Content_Navigation_Children_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_Children_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmpi_Navigation_Record_Content_Navigation_Link = {
  link: _Cmpi_Navigation_Record_Content_Navigation_Link_Link;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Link_Link = {
  external?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Link_Link_Internal>;
};

export type _Cmpi_Navigation_Record_Content_Navigation_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Navigation_Record_Content_Navigation_Link_Link_External_Target>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Navigation_Record_Content_Navigation_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Navigation_Record_Content_Navigation_ParentForContentOfType {
  /** event */
  Event = 'event',
  /** multimedia */
  Multimedia = 'multimedia',
  /** news */
  News = 'news',
  /** project */
  Project = 'project',
  /** publication */
  Publication = 'publication'
}

export type _Cmpi_Navigation_Record_Create = {
  content: _Cmpi_Navigation_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Navigation_Record_Update = {
  content: _Cmpi_Navigation_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_News = {
  __typename?: '_cmpi_news';
  archive?: Maybe<_Cmp_News_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_News_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_News_Record>;
  publish?: Maybe<_Cmp_News_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_News_Record;
};


export type _Cmpi_NewsArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NewsCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_News_Record_Create;
};


export type _Cmpi_NewsDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NewsMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_NewsPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_NewsUpdateArgs = {
  input: _Cmpi_News_Record_Update;
};

export type _Cmpi_News_Record_Content = {
  blocks: Array<_Cmpi_News_Record_Content_Blocks>;
  entryDate?: InputMaybe<Scalars['DateTime']['input']>;
  entryDateText?: InputMaybe<I18n_String_Input>;
  entryDates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  leadText: I18n_String_Input;
  module?: InputMaybe<Ref_Input>;
  newsDate: Scalars['DateTime']['input'];
  shortLeadText: I18n_String_Input;
  teaserTitle: I18n_String_Input;
  title: I18n_String_Input;
  video?: InputMaybe<_Cmpi_News_Record_Content_Video>;
};

export type _Cmpi_News_Record_Content_Blocks = {
  accordion?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion>;
  anchor?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CodeSnippet>;
  ctaButton?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CtaButton>;
  customQuote?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CustomQuote>;
  embed?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Embed>;
  faqList?: InputMaybe<_Cmpi_News_Record_Content_Blocks_FaqList>;
  image?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Image>;
  linkList?: InputMaybe<_Cmpi_News_Record_Content_Blocks_LinkList>;
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_RichText>;
  spacer?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Spacer>;
  table?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Table>;
  title?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Title>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion = {
  accordion: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion = {
  content: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['input'];
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content = {
  anchor?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet>;
  faqList?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList>;
  image?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Image>;
  linkList?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList>;
  news?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_News>;
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText>;
  table?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  size?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  borderColor: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  linkList: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  link: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  cellWidth: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  content: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  content: Array<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText>;
};

export type _Cmpi_News_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export enum _Cmpi_News_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmpi_News_Record_Content_Blocks_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_CodeSnippet = {
  size?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_News_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_News_Record_Content_Blocks_CtaButton = {
  buttonLabel: I18n_String_Input;
  buttonLink: _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink;
  position: _Cmpi_News_Record_Content_Blocks_CtaButton_Position;
};

export type _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink = {
  link: _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link;
};

export type _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal>;
};

export type _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_News_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_News_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmpi_News_Record_Content_Blocks_CustomQuote = {
  author: Scalars['String']['input'];
  quote: I18n_String_Input;
  source: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Embed = {
  embedCode?: InputMaybe<I18n_String_Input>;
  type: _Cmpi_News_Record_Content_Blocks_Embed_Type;
};

export type _Cmpi_News_Record_Content_Blocks_Embed_Type = {
  other?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Embed_Type_Other>;
  vimeoVideo?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Embed_Type_VimeoVideo>;
  youTubeVideo?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Embed_Type_YouTubeVideo>;
};

export type _Cmpi_News_Record_Content_Blocks_Embed_Type_Other = {
  height: I18n_Int_Input;
  styleCustom: I18n_String_Input;
  title: I18n_String_Input;
  url: I18n_String_Input;
  width: I18n_Int_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Embed_Type_VimeoVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Embed_Type_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_News_Record_Content_Blocks_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_News_Record_Content_Blocks_Image = {
  borderColor: _Cmpi_News_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_News_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_News_Record_Content_Blocks_LinkList = {
  linkList: Array<_Cmpi_News_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmpi_News_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_LinkList_LinkList = {
  link: _Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_News_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_News_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_News_Record_Content_Blocks_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_News_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_News_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_News_Record_Content_Blocks_Spacer = {
  height: Scalars['Int']['input'];
};

export type _Cmpi_News_Record_Content_Blocks_Table = {
  cellWidth: _Cmpi_News_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_News_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmpi_News_Record_Content_Blocks_Table_Rows>;
};

export type _Cmpi_News_Record_Content_Blocks_Table_CellWidth = {
  content: Array<_Cmpi_News_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmpi_News_Record_Content_Blocks_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_News_Record_Content_Blocks_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_News_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmpi_News_Record_Content_Blocks_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Table_Header_Content_RichText>;
};

export type _Cmpi_News_Record_Content_Blocks_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Table_Rows = {
  content: Array<_Cmpi_News_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_News_Record_Content_Blocks_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_News_Record_Content_Blocks_Table_Rows_Content_RichText>;
};

export type _Cmpi_News_Record_Content_Blocks_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_News_Record_Content_Blocks_Title = {
  lead: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Content_Video = {
  youtube?: InputMaybe<_Cmpi_News_Record_Content_Video_Youtube>;
};

export type _Cmpi_News_Record_Content_Video_Youtube = {
  youtubeId: I18n_String_Input;
};

export type _Cmpi_News_Record_Create = {
  content: _Cmpi_News_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_News_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_News_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  categories: Array<InputMaybe<Ref_Input>>;
  columnRightNew?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  presentOn: Array<InputMaybe<Ref_Input>>;
  robotsMetaDirectives?: InputMaybe<_Cmpi_News_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew = {
  columnRight: Array<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['input'];
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight = {
  button?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button>;
  contact?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Contact>;
  text?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Text>;
  title?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Title>;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  link: _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal>;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  contact?: InputMaybe<Ref_Input>;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  infoBox: I18n_RichText_Input;
};

export type _Cmpi_News_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  title: I18n_String_Input;
};

export enum _Cmpi_News_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_News_Record_Update = {
  content: _Cmpi_News_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_News_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Page = {
  __typename?: '_cmpi_page';
  archive?: Maybe<_Cmp_Page_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Page_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Page_Record>;
  publish?: Maybe<_Cmp_Page_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Page_Record;
};


export type _Cmpi_PageArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PageCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Page_Record_Create;
};


export type _Cmpi_PageDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PageMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_PagePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PageUpdateArgs = {
  input: _Cmpi_Page_Record_Update;
};

export type _Cmpi_Page_Record_Content = {
  blocks: Array<_Cmpi_Page_Record_Content_Blocks>;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  leadText: I18n_String_Input;
  teaserText?: InputMaybe<I18n_String_Input>;
  teaserTitle: I18n_String_Input;
  tileImage?: InputMaybe<_Cmpi_Page_Record_Content_TileImage>;
  title: I18n_String_Input;
  video?: InputMaybe<_Cmpi_Page_Record_Content_Video>;
};

export type _Cmpi_Page_Record_Content_Blocks = {
  accordion?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion>;
  anchor?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CodeSnippet>;
  ctaButton?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CtaButton>;
  custom?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Custom>;
  customQuote?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CustomQuote>;
  embed?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Embed>;
  faqList?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FaqList>;
  filterViews?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews>;
  hero?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Hero>;
  image?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Image>;
  linkList?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_LinkList>;
  news?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_News>;
  newsGrid?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid>;
  overviewResults?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_OverviewResults>;
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_RichText>;
  slider?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Slider>;
  spacer?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Spacer>;
  table?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Table>;
  teaserCarousel?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel>;
  teaserSection?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection>;
  teaserSlider?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSlider>;
  title?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Title>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion = {
  accordion: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion = {
  content: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['input'];
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content = {
  anchor?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet>;
  faqList?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList>;
  image?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image>;
  linkList?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList>;
  news?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_News>;
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText>;
  table?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  borderColor: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  linkList: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  link: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  cellWidth: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  content: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  content: Array<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText>;
};

export type _Cmpi_Page_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export enum _Cmpi_Page_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmpi_Page_Record_Content_Blocks_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Page_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Page_Record_Content_Blocks_CtaButton = {
  buttonLabel: I18n_String_Input;
  buttonLink: _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink;
  position: _Cmpi_Page_Record_Content_Blocks_CtaButton_Position;
};

export type _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink = {
  link: _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Page_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmpi_Page_Record_Content_Blocks_Custom = {
  contentType: Scalars['Boolean']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_CustomQuote = {
  author: Scalars['String']['input'];
  quote: I18n_String_Input;
  source: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Embed = {
  embedCode?: InputMaybe<I18n_String_Input>;
  type: _Cmpi_Page_Record_Content_Blocks_Embed_Type;
};

export type _Cmpi_Page_Record_Content_Blocks_Embed_Type = {
  other?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Embed_Type_Other>;
  vimeoVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Embed_Type_VimeoVideo>;
  youTubeVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo>;
};

export type _Cmpi_Page_Record_Content_Blocks_Embed_Type_Other = {
  height: I18n_Int_Input;
  styleCustom: I18n_String_Input;
  title: I18n_String_Input;
  url: I18n_String_Input;
  width: I18n_Int_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Embed_Type_VimeoVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Embed_Type_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews = {
  contentTypeUnion: _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion = {
  bridgeProjects?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects>;
  events?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events>;
  multimedia?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia>;
  news?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News>;
  projects?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects>;
  publications?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications>;
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_BridgeProjects = {
  description: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Events = {
  description: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Multimedia = {
  description: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News = {
  categoriesNews: Array<InputMaybe<Ref_Input>>;
  filter?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter>;
  hideFilter: Scalars['Boolean']['input'];
  tags: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter = {
  monthFilter?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter>;
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter = {
  order: _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Order;
  year: Scalars['Int']['input'];
};

export enum _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_News_Filter_MonthFilter_Order {
  /** Ascending */
  Ascending = 'ASCENDING',
  /** Descending */
  Descending = 'DESCENDING'
}

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Projects = {
  description: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_FilterViews_ContentTypeUnion_Publications = {
  description: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Hero = {
  image?: InputMaybe<Ref_Input>;
  link?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Hero_Link>;
  richText: I18n_RichText_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Hero_Link = {
  linkList: Array<_Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList>;
  listType: _Cmpi_Page_Record_Content_Blocks_Hero_Link_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList = {
  link: _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_Hero_Link_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Page_Record_Content_Blocks_Hero_Link_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Page_Record_Content_Blocks_Image = {
  borderColor: _Cmpi_Page_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Page_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Page_Record_Content_Blocks_LinkList = {
  linkList: Array<_Cmpi_Page_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmpi_Page_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList = {
  link: _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Page_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Page_Record_Content_Blocks_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink>;
  gridSlots: Array<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots>;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink = {
  link: _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots = {
  slotType: _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType = {
  disturber?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber>;
  reference?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference>;
  soMeCard?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard>;
  stream?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber = {
  displayGradient: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  link?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link>;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link = {
  link: _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Disturber_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Reference = {
  reference?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard = {
  cardType: _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType = {
  elfsightEmbed?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed>;
  linkedInPost?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost>;
  tweet?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet>;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_ElfsightEmbed = {
  embedCode: I18n_String_Input;
  previewTitle: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_LinkedInPost = {
  embedCode: I18n_String_Input;
  previewTitle: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_SoMeCard_CardType_Tweet = {
  previewTitle: Scalars['String']['input'];
  tweetUrl: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream = {
  stream: _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Stream;
};

export enum _Cmpi_Page_Record_Content_Blocks_NewsGrid_GridSlots_SlotType_Stream_Stream {
  /** News */
  News = 'news'
}

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults = {
  disableFilter: Scalars['Boolean']['input'];
  results: Array<_Cmpi_Page_Record_Content_Blocks_OverviewResults_Results>;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results = {
  reference?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Reference>;
  video?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video>;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Reference = {
  ref?: InputMaybe<Ref_Input>;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video = {
  image?: InputMaybe<Ref_Input>;
  module?: InputMaybe<Ref_Input>;
  text: I18n_String_Input;
  title: I18n_String_Input;
  type: _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type = {
  vimeoVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo>;
  youTubeVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo>;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_VimeoVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_OverviewResults_Results_Video_Type_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Page_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Page_Record_Content_Blocks_Slider = {
  slider: Array<_Cmpi_Page_Record_Content_Blocks_Slider_Slider>;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Slider_Slider = {
  default?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Slider_Slider_Default>;
  youTubeVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo>;
};

export type _Cmpi_Page_Record_Content_Blocks_Slider_Slider_Default = {
  image?: InputMaybe<Ref_Input>;
  text: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Slider_Slider_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Spacer = {
  height: Scalars['Int']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Table = {
  cellWidth: _Cmpi_Page_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Page_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmpi_Page_Record_Content_Blocks_Table_Rows>;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_CellWidth = {
  content: Array<_Cmpi_Page_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Page_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Table_Header_Content_RichText>;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Rows = {
  content: Array<_Cmpi_Page_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_Table_Rows_Content_RichText>;
};

export type _Cmpi_Page_Record_Content_Blocks_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink>;
  slider: Array<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel_Slider>;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink = {
  link: _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserCarousel_Slider = {
  ref?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink>;
  grid?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Grid>;
  teaser: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser;
  text: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink = {
  link: _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSection_Grid {
  /** 2-spaltiges Layout */
  Cols_2 = 'cols_2',
  /** 3-spaltiges Layout */
  Cols_3 = 'cols_3'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser = {
  auto?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto>;
  manual?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto = {
  cellNumber: Scalars['Int']['input'];
  filterCategory?: InputMaybe<Ref_Input>;
  filterDate?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_FilterDate>;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Auto_FilterDate {
  /** after */
  After = 'after',
  /** before */
  Before = 'before'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual = {
  list: Array<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List = {
  disturber?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber>;
  reference?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference>;
  soMeCard?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard>;
  video?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber = {
  backgroundColor: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_BackgroundColor;
  image?: InputMaybe<Ref_Input>;
  link?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link>;
  text: I18n_RichText_Input;
  title: I18n_String_Input;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link = {
  link: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_Internal>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Disturber_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference = {
  ref?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard = {
  cardType: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType = {
  elfsightEmbed?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed>;
  linkedInPost?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost>;
  tweet?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_ElfsightEmbed = {
  embedCode: I18n_String_Input;
  previewTitle: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_LinkedInPost = {
  embedCode: I18n_String_Input;
  previewTitle: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_SoMeCard_CardType_Tweet = {
  previewTitle: Scalars['String']['input'];
  tweetUrl: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video = {
  image?: InputMaybe<Ref_Input>;
  title: I18n_String_Input;
  video: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video = {
  embed?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed = {
  embedCode?: InputMaybe<I18n_String_Input>;
  type: _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type = {
  other?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other>;
  vimeoVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo>;
  youTubeVideo?: InputMaybe<_Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo>;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_Other = {
  height: I18n_Int_Input;
  styleCustom: I18n_String_Input;
  title: I18n_String_Input;
  url: I18n_String_Input;
  width: I18n_Int_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_VimeoVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Video_Video_Embed_Type_YouTubeVideo = {
  videoID: I18n_Id_Input;
};

export type _Cmpi_Page_Record_Content_Blocks_TeaserSlider = {
  contentType: _Cmpi_Page_Record_Content_Blocks_TeaserSlider_ContentType;
  filterCriteriaCategories: Array<InputMaybe<Ref_Input>>;
  filterCriteriaTags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export enum _Cmpi_Page_Record_Content_Blocks_TeaserSlider_ContentType {
  /** News */
  News = 'news',
  /** Project */
  Project = 'project'
}

export type _Cmpi_Page_Record_Content_Blocks_Title = {
  lead: I18n_String_Input;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Content_TileImage = {
  tileImage?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Page_Record_Content_Video = {
  youtube?: InputMaybe<_Cmpi_Page_Record_Content_Video_Youtube>;
};

export type _Cmpi_Page_Record_Content_Video_Youtube = {
  youtubeId: I18n_String_Input;
};

export type _Cmpi_Page_Record_Create = {
  content: _Cmpi_Page_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Page_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Page_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  columnRightNew?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Page_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew = {
  columnRight: Array<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['input'];
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight = {
  button?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button>;
  contact?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact>;
  text?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Text>;
  title?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Title>;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  link: _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal>;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  contact?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  infoBox: I18n_RichText_Input;
};

export type _Cmpi_Page_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  title: I18n_String_Input;
};

export enum _Cmpi_Page_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Page_Record_Update = {
  content: _Cmpi_Page_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Page_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Project = {
  __typename?: '_cmpi_project';
  archive?: Maybe<_Cmp_Project_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Project_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Project_Record>;
  publish?: Maybe<_Cmp_Project_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Project_Record;
};


export type _Cmpi_ProjectArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ProjectCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Project_Record_Create;
};


export type _Cmpi_ProjectDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ProjectMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_ProjectPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_ProjectUpdateArgs = {
  input: _Cmpi_Project_Record_Update;
};

export type _Cmpi_Project_Record_Content = {
  blocks: Array<_Cmpi_Project_Record_Content_Blocks>;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  leadText: I18n_String_Input;
  module?: InputMaybe<Ref_Input>;
  projectManagement: Scalars['String']['input'];
  projectNumber?: InputMaybe<Scalars['String']['input']>;
  projectResponsibles: I18n_RichText_Input;
  shortLeadText: I18n_String_Input;
  status: _Cmpi_Project_Record_Content_Status;
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks = {
  accordion?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion>;
  anchor?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CodeSnippet>;
  ctaButton?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CtaButton>;
  customQuote?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CustomQuote>;
  image?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Image>;
  linkList?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_LinkList>;
  news?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_News>;
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_RichText>;
  spacer?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Spacer>;
  table?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Table>;
  title?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Title>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion = {
  accordion: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion>;
  backgroundColor?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_BackgroundColor>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion = {
  content: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content>;
  id: Scalars['ID']['input'];
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content = {
  anchor?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor>;
  codeSnippet?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet>;
  faqList?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList>;
  image?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image>;
  linkList?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList>;
  news?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_News>;
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText>;
  table?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_FaqList = {
  faqList: Array<InputMaybe<Ref_Input>>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image = {
  borderColor: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList = {
  linkList: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList>;
  listType: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList = {
  link: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table = {
  cellWidth: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header;
  rows: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth = {
  content: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows = {
  content: Array<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText>;
};

export type _Cmpi_Project_Record_Content_Blocks_Accordion_Accordion_Content_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export enum _Cmpi_Project_Record_Content_Blocks_Accordion_BackgroundColor {
  /** Grau */
  Gray = 'gray'
}

export type _Cmpi_Project_Record_Content_Blocks_Anchor = {
  anchorName: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_CodeSnippet = {
  size?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size>;
  snippet: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size = {
  aspectRatio?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio>;
};

export type _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio = {
  desktop: _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop;
  mobile: _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile;
};

export enum _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Desktop {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export enum _Cmpi_Project_Record_Content_Blocks_CodeSnippet_Size_AspectRatio_Mobile {
  /** 1:1 */
  Aspectratio_1_1 = 'aspectratio_1_1',
  /** 3:4 */
  Aspectratio_3_4 = 'aspectratio_3_4',
  /** 4:3 */
  Aspectratio_4_3 = 'aspectratio_4_3',
  /** 5:8 */
  Aspectratio_5_8 = 'aspectratio_5_8',
  /** 8:5 */
  Aspectratio_8_5 = 'aspectratio_8_5',
  /** 9:16 */
  Aspectratio_9_16 = 'aspectratio_9_16',
  /** 16:9 */
  Aspectratio_16_9 = 'aspectratio_16_9'
}

export type _Cmpi_Project_Record_Content_Blocks_CtaButton = {
  buttonLabel: I18n_String_Input;
  buttonLink: _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink;
  position: _Cmpi_Project_Record_Content_Blocks_CtaButton_Position;
};

export type _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink = {
  link: _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link;
};

export type _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal>;
};

export type _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Project_Record_Content_Blocks_CtaButton_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Project_Record_Content_Blocks_CtaButton_Position {
  /** Mitte */
  Center = 'center',
  /** Links */
  Left = 'left',
  /** Rechts */
  Right = 'right'
}

export type _Cmpi_Project_Record_Content_Blocks_CustomQuote = {
  author: Scalars['String']['input'];
  quote: I18n_String_Input;
  source: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Image = {
  borderColor: _Cmpi_Project_Record_Content_Blocks_Image_BorderColor;
  caption: I18n_String_Input;
  hasBorder: Scalars['Boolean']['input'];
  image?: InputMaybe<Ref_Input>;
  isZoomable: Scalars['Boolean']['input'];
};

export enum _Cmpi_Project_Record_Content_Blocks_Image_BorderColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Project_Record_Content_Blocks_LinkList = {
  linkList: Array<_Cmpi_Project_Record_Content_Blocks_LinkList_LinkList>;
  listType: _Cmpi_Project_Record_Content_Blocks_LinkList_ListType;
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList = {
  link: _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link;
  linkText: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link = {
  external?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_External>;
  internal?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal>;
};

export type _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Target>;
};

export enum _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Project_Record_Content_Blocks_LinkList_LinkList_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export enum _Cmpi_Project_Record_Content_Blocks_LinkList_ListType {
  /** Einfach */
  List = 'list',
  /** Nummeriert */
  OrderedList = 'orderedList',
  /** Unsortiert */
  UnorderedList = 'unorderedList'
}

export type _Cmpi_Project_Record_Content_Blocks_News = {
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_RichText = {
  backgroundColor?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_RichText_BackgroundColor>;
  text: I18n_RichText_Input;
};

export enum _Cmpi_Project_Record_Content_Blocks_RichText_BackgroundColor {
  /** Grau */
  Gray = 'gray',
  /** Primäre Farbe */
  Primarypale = 'primarypale',
  /** Sekundäre Farbe */
  Secondarypale = 'secondarypale'
}

export type _Cmpi_Project_Record_Content_Blocks_Spacer = {
  height: Scalars['Int']['input'];
};

export type _Cmpi_Project_Record_Content_Blocks_Table = {
  cellWidth: _Cmpi_Project_Record_Content_Blocks_Table_CellWidth;
  columnCount: Scalars['Int']['input'];
  disableMargin: Scalars['Boolean']['input'];
  header: _Cmpi_Project_Record_Content_Blocks_Table_Header;
  rows: Array<_Cmpi_Project_Record_Content_Blocks_Table_Rows>;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_CellWidth = {
  content: Array<_Cmpi_Project_Record_Content_Blocks_Table_CellWidth_Content>;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_CellWidth_Content = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Header = {
  active: Scalars['Boolean']['input'];
  content: Array<_Cmpi_Project_Record_Content_Blocks_Table_Header_Content>;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Header_Content = {
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Table_Header_Content_RichText>;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Header_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Rows = {
  content: Array<_Cmpi_Project_Record_Content_Blocks_Table_Rows_Content>;
  id: Scalars['ID']['input'];
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Rows_Content = {
  richText?: InputMaybe<_Cmpi_Project_Record_Content_Blocks_Table_Rows_Content_RichText>;
};

export type _Cmpi_Project_Record_Content_Blocks_Table_Rows_Content_RichText = {
  text: I18n_RichText_Input;
};

export type _Cmpi_Project_Record_Content_Blocks_Title = {
  lead: I18n_String_Input;
  title: I18n_String_Input;
};

export enum _Cmpi_Project_Record_Content_Status {
  /** completed */
  Completed = 'completed',
  /** ongoing */
  Ongoing = 'ongoing'
}

export type _Cmpi_Project_Record_Create = {
  content: _Cmpi_Project_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Project_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Project_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  columnRightNew?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Project_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew = {
  columnRight: Array<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight>;
  showSocialMediaButtons: Scalars['Boolean']['input'];
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight = {
  button?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button>;
  contact?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact>;
  text?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Text>;
  title?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Title>;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button = {
  buttonLabel: I18n_String_Input;
  buttonLink?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink>;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink = {
  link: _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link = {
  external?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal>;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target>;
};

export enum _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Button_ButtonLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Contact = {
  contact?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Text = {
  infoBox: I18n_RichText_Input;
};

export type _Cmpi_Project_Record_Meta_ColumnRightNew_ColumnRight_Title = {
  title: I18n_String_Input;
};

export enum _Cmpi_Project_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Project_Record_Update = {
  content: _Cmpi_Project_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Project_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Publication = {
  __typename?: '_cmpi_publication';
  archive?: Maybe<_Cmp_Publication_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Publication_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Publication_Record>;
  publish?: Maybe<_Cmp_Publication_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Publication_Record;
};


export type _Cmpi_PublicationArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Publication_Record_Create;
};


export type _Cmpi_PublicationDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_PublicationPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationUpdateArgs = {
  input: _Cmpi_Publication_Record_Update;
};

export type _Cmpi_PublicationType = {
  __typename?: '_cmpi_publicationType';
  archive?: Maybe<_Cmp_PublicationType_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_PublicationType_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_PublicationType_Record>;
  publish?: Maybe<_Cmp_PublicationType_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_PublicationType_Record;
};


export type _Cmpi_PublicationTypeArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationTypeCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_PublicationType_Record_Create;
};


export type _Cmpi_PublicationTypeDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationTypeMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_PublicationTypePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_PublicationTypeUpdateArgs = {
  input: _Cmpi_PublicationType_Record_Update;
};

export type _Cmpi_PublicationType_Record_Content = {
  category: I18n_String_Input;
  order: Scalars['Int']['input'];
};

export type _Cmpi_PublicationType_Record_Create = {
  content: _Cmpi_PublicationType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_PublicationType_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_PublicationType_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_PublicationType_Record_Update = {
  content: _Cmpi_PublicationType_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_PublicationType_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Publication_Record_Content = {
  bibliography: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  download?: InputMaybe<Ref_Input>;
  image?: InputMaybe<Ref_Input>;
  isImageZoomable?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<_Cmpi_Publication_Record_Content_Link>;
  module?: InputMaybe<Ref_Input>;
  publicationType?: InputMaybe<Ref_Input>;
  publisher: Scalars['String']['input'];
  teaserText?: InputMaybe<I18n_String_Input>;
  tileImage?: InputMaybe<_Cmpi_Publication_Record_Content_TileImage>;
  title: I18n_String_Input;
};

export type _Cmpi_Publication_Record_Content_Link = {
  link: _Cmpi_Publication_Record_Content_Link_Link;
};

export type _Cmpi_Publication_Record_Content_Link_Link = {
  external?: InputMaybe<_Cmpi_Publication_Record_Content_Link_Link_External>;
  internal?: InputMaybe<_Cmpi_Publication_Record_Content_Link_Link_Internal>;
};

export type _Cmpi_Publication_Record_Content_Link_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Publication_Record_Content_Link_Link_External_Target>;
};

export enum _Cmpi_Publication_Record_Content_Link_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Publication_Record_Content_Link_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Publication_Record_Content_TileImage = {
  tileImage?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Publication_Record_Create = {
  content: _Cmpi_Publication_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Publication_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Publication_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  description: I18n_String_Input;
  migrationsUrl: I18n_String_Input;
  robotsMetaDirectives?: InputMaybe<_Cmpi_Publication_Record_Meta_RobotsMetaDirectives>;
  shareImage?: InputMaybe<Ref_Input>;
  tags: Array<InputMaybe<Ref_Input>>;
  title: I18n_String_Input;
};

export enum _Cmpi_Publication_Record_Meta_RobotsMetaDirectives {
  /** Index Follow */
  IndexFollow = 'indexFollow',
  /** Index NoFollow */
  IndexNoFollow = 'indexNoFollow',
  /** NoIndex Follow */
  NoIndexFollow = 'noIndexFollow',
  /** NoIndex NoFollow */
  NoIndexNoFollow = 'noIndexNoFollow'
}

export type _Cmpi_Publication_Record_Update = {
  content: _Cmpi_Publication_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Publication_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Tag = {
  __typename?: '_cmpi_tag';
  archive?: Maybe<_Cmp_Tag_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Tag_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Tag_Record>;
  publish?: Maybe<_Cmp_Tag_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Tag_Record;
};


export type _Cmpi_TagArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_TagCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Tag_Record_Create;
};


export type _Cmpi_TagDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_TagMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_TagPublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_TagUpdateArgs = {
  input: _Cmpi_Tag_Record_Update;
};

export type _Cmpi_Tag_Record_Content = {
  tag: I18n_String_Input;
};

export type _Cmpi_Tag_Record_Create = {
  content: _Cmpi_Tag_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Tag_Record_Meta;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Tag_Record_Meta = {
  allowMigration?: InputMaybe<Scalars['Boolean']['input']>;
  archive?: InputMaybe<Scalars['Boolean']['input']>;
  migrationsUrl: I18n_String_Input;
};

export type _Cmpi_Tag_Record_Update = {
  content: _Cmpi_Tag_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  meta: _Cmpi_Tag_Record_Meta;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Website = {
  __typename?: '_cmpi_website';
  archive?: Maybe<_Cmp_Website_Record>;
  /** Create a new revision of an existing content if id is present. Otherwise a new content with revision 0 will be created. */
  create: _Cmp_Website_Record;
  /** Deletes all revisions of a content */
  delete: Scalars['Boolean']['output'];
  moveToTrash?: Maybe<_Cmp_Website_Record>;
  publish?: Maybe<_Cmp_Website_Record>;
  /** Update a revision of an existing content */
  update: _Cmp_Website_Record;
};


export type _Cmpi_WebsiteArchiveArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_WebsiteCreateArgs = {
  derivedFromRevision?: InputMaybe<Scalars['Int']['input']>;
  input: _Cmpi_Website_Record_Create;
};


export type _Cmpi_WebsiteDeleteArgs = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_WebsiteMoveToTrashArgs = {
  deletionDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  revision?: InputMaybe<Scalars['Int']['input']>;
};


export type _Cmpi_WebsitePublishArgs = {
  dePublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  fixOverlappingPublicationDates?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  publicationDate: Scalars['DateTime']['input'];
  revision: Scalars['Int']['input'];
};


export type _Cmpi_WebsiteUpdateArgs = {
  input: _Cmpi_Website_Record_Update;
};

export type _Cmpi_Website_Record_Content = {
  callForProposalCategory?: InputMaybe<Ref_Input>;
  dataPortalBaseLink?: InputMaybe<I18n_String_Input>;
  defaultFallbackImage?: InputMaybe<Ref_Input>;
  defaultMetaDescription: I18n_String_Input;
  defaultMetaTitle: I18n_String_Input;
  defaultShareImage?: InputMaybe<Ref_Input>;
  error404Page?: InputMaybe<Ref_Input>;
  favicon?: InputMaybe<Ref_Input>;
  footer?: InputMaybe<Ref_Input>;
  googleApiKey: Scalars['String']['input'];
  googleApiKeyV4: Scalars['String']['input'];
  googleTagManagerKey: Scalars['String']['input'];
  headerLink?: InputMaybe<_Cmpi_Website_Record_Content_HeaderLink>;
  headerLinkText: I18n_String_Input;
  homePage?: InputMaybe<Ref_Input>;
  isBridge?: InputMaybe<Scalars['Boolean']['input']>;
  navigator?: InputMaybe<Ref_Input>;
  newsCategory?: InputMaybe<Ref_Input>;
  nfpIdentifier: Scalars['String']['input'];
  pressReleaseCategory?: InputMaybe<Ref_Input>;
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
  title: I18n_String_Input;
  websiteLogo?: InputMaybe<Ref_Input>;
};

export type _Cmpi_Website_Record_Content_HeaderLink = {
  link: _Cmpi_Website_Record_Content_HeaderLink_Link;
};

export type _Cmpi_Website_Record_Content_HeaderLink_Link = {
  external?: InputMaybe<_Cmpi_Website_Record_Content_HeaderLink_Link_External>;
  internal?: InputMaybe<_Cmpi_Website_Record_Content_HeaderLink_Link_Internal>;
};

export type _Cmpi_Website_Record_Content_HeaderLink_Link_External = {
  href: I18n_String_Input;
  nofollow: Scalars['Boolean']['input'];
  target?: InputMaybe<_Cmpi_Website_Record_Content_HeaderLink_Link_External_Target>;
};

export enum _Cmpi_Website_Record_Content_HeaderLink_Link_External_Target {
  /** _blank: a new tab/window */
  Blank = '_blank',
  /** _self: the current browsing context. (Default) */
  Self = '_self'
}

export type _Cmpi_Website_Record_Content_HeaderLink_Link_Internal = {
  fragment?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Ref_Input>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
};

export type _Cmpi_Website_Record_Create = {
  content: _Cmpi_Website_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActiveI18n: I18n_Boolean_Input;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type _Cmpi_Website_Record_Update = {
  content: _Cmpi_Website_Record_Content;
  disableSearch: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isActiveI18n: I18n_Boolean_Input;
  revision?: InputMaybe<Scalars['Int']['input']>;
  shared: Scalars['Boolean']['input'];
  slugI18n: I18n_String_Input;
  title: Scalars['String']['input'];
};

export type Content = {
  __typename?: 'content';
  _all: All;
  bridgeProject: _Cmp_BridgeProject;
  bridgeProjectInnovationField: _Cmp_BridgeProjectInnovationField;
  bridgeProjectInstitution: _Cmp_BridgeProjectInstitution;
  bridgeProjectType: _Cmp_BridgeProjectType;
  categoryNews: _Cmp_CategoryNews;
  contact: _Cmp_Contact;
  domain: _Cmp_Domain;
  event: _Cmp_Event;
  eventType: _Cmp_EventType;
  faq: _Cmp_Faq;
  footer: _Cmp_Footer;
  media: _Cmp_Media;
  module: _Cmp_Module;
  multimedia: _Cmp_Multimedia;
  navigation: _Cmp_Navigation;
  news: _Cmp_News;
  page: _Cmp_Page;
  project: _Cmp_Project;
  publication: _Cmp_Publication;
  publicationType: _Cmp_PublicationType;
  tag: _Cmp_Tag;
  website: _Cmp_Website;
};

export enum ContentContextEnum {
  Local = 'local',
  Peers = 'peers'
}

export enum ContentTypeEnum {
  BridgeProject = 'bridgeProject',
  BridgeProjectInnovationField = 'bridgeProjectInnovationField',
  BridgeProjectInstitution = 'bridgeProjectInstitution',
  BridgeProjectType = 'bridgeProjectType',
  CategoryNews = 'categoryNews',
  Contact = 'contact',
  Domain = 'domain',
  Event = 'event',
  EventType = 'eventType',
  Faq = 'faq',
  Footer = 'footer',
  Media = 'media',
  Module = 'module',
  Multimedia = 'multimedia',
  Navigation = 'navigation',
  News = 'news',
  Page = 'page',
  Project = 'project',
  Publication = 'publication',
  PublicationType = 'publicationType',
  Tag = 'tag',
  Website = 'website'
}

export type Content_Mutations = {
  __typename?: 'content_mutations';
  _all: AllMutations;
  bridgeProject: _Cmpi_BridgeProject;
  bridgeProjectInnovationField: _Cmpi_BridgeProjectInnovationField;
  bridgeProjectInstitution: _Cmpi_BridgeProjectInstitution;
  bridgeProjectType: _Cmpi_BridgeProjectType;
  categoryNews: _Cmpi_CategoryNews;
  contact: _Cmpi_Contact;
  domain: _Cmpi_Domain;
  event: _Cmpi_Event;
  eventType: _Cmpi_EventType;
  faq: _Cmpi_Faq;
  footer: _Cmpi_Footer;
  media: _Cmpi_Media;
  module: _Cmpi_Module;
  multimedia: _Cmpi_Multimedia;
  navigation: _Cmpi_Navigation;
  news: _Cmpi_News;
  page: _Cmpi_Page;
  project: _Cmpi_Project;
  publication: _Cmpi_Publication;
  publicationType: _Cmpi_PublicationType;
  tag: _Cmpi_Tag;
  website: _Cmpi_Website;
};

export type Filter_BridgeProject = {
  content__keywords?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_BridgeProject>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_BridgeProjectInnovationField = {
  options?: InputMaybe<Options_Filter_BridgeProjectInnovationField>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_BridgeProjectInstitution = {
  options?: InputMaybe<Options_Filter_BridgeProjectInstitution>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_BridgeProjectType = {
  options?: InputMaybe<Options_Filter_BridgeProjectType>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_CategoryNews = {
  content__order?: InputMaybe<FilterInt>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_CategoryNews>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Contact = {
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Contact>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Domain = {
  content__domains?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Domain>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Event = {
  content__endDate?: InputMaybe<FilterDate>;
  content__eventType__recordId?: InputMaybe<FilterReference>;
  content__startDate?: InputMaybe<FilterDate>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_Event>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_EventType = {
  content__order?: InputMaybe<FilterInt>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_EventType>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Faq = {
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Faq>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Footer = {
  options?: InputMaybe<Options_Filter_Footer>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Media = {
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Media>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Module = {
  content__order?: InputMaybe<FilterInt>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Module>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Multimedia = {
  content__newsDate?: InputMaybe<FilterDate>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_Multimedia>;
  term?: InputMaybe<Scalars['String']['input']>;
  union__content__content?: InputMaybe<Filter_Multimedia_Content_Content>;
};

export type Filter_Multimedia_Content_Content = {
  /** equal to */
  eq?: InputMaybe<Filter_Multimedia_Content_Content_Union>;
  /** not equal to */
  ne?: InputMaybe<Filter_Multimedia_Content_Content_Union>;
};

export enum Filter_Multimedia_Content_Content_Union {
  /** gallery */
  Gallery = 'gallery',
  /** video */
  Video = 'video'
}

export type Filter_Navigation = {
  options?: InputMaybe<Options_Filter_Navigation>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_News = {
  content__entryDate?: InputMaybe<FilterDate>;
  content__entryDates?: InputMaybe<FilterDate>;
  content__newsDate?: InputMaybe<FilterDate>;
  i18n__content__entryDateText?: InputMaybe<FilterText>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__categories__recordId?: InputMaybe<FilterReference>;
  meta__presentOn__recordId?: InputMaybe<FilterReference>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_News>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Page = {
  content__blocks__filterViews__contentTypeUnion__news__categoriesNews__recordId?: InputMaybe<FilterReference>;
  content__blocks__filterViews__contentTypeUnion__news__tags__recordId?: InputMaybe<FilterReference>;
  content__blocks__teaserSlider__filterCriteriaTags__recordId?: InputMaybe<FilterReference>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_Page>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Project = {
  content__module__recordId?: InputMaybe<FilterReference>;
  content__projectManagement?: InputMaybe<FilterText>;
  content__status?: InputMaybe<Filter_Project_Content_Status>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_Project>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Project_Content_Status = {
  /** equal to */
  eq?: InputMaybe<Filter_Project_Content_Status_Enum>;
  /** not equal to */
  ne?: InputMaybe<Filter_Project_Content_Status_Enum>;
};

export enum Filter_Project_Content_Status_Enum {
  /** completed */
  Completed = 'completed',
  /** ongoing */
  Ongoing = 'ongoing'
}

export type Filter_Publication = {
  content__date?: InputMaybe<FilterDate>;
  content__publicationType__recordId?: InputMaybe<FilterReference>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  meta__tags__recordId?: InputMaybe<FilterReference>;
  options?: InputMaybe<Options_Filter_Publication>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_PublicationType = {
  content__order?: InputMaybe<FilterInt>;
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_PublicationType>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Tag = {
  i18n__meta__migrationsUrl?: InputMaybe<FilterText>;
  options?: InputMaybe<Options_Filter_Tag>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Filter_Website = {
  options?: InputMaybe<Options_Filter_Website>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type I18n_Boolean = {
  __typename?: 'i18n_Boolean';
  de?: Maybe<Scalars['Boolean']['output']>;
  en?: Maybe<Scalars['Boolean']['output']>;
  fr?: Maybe<Scalars['Boolean']['output']>;
  it?: Maybe<Scalars['Boolean']['output']>;
};

export type I18n_Boolean_Input = {
  de?: InputMaybe<Scalars['Boolean']['input']>;
  en?: InputMaybe<Scalars['Boolean']['input']>;
  fr?: InputMaybe<Scalars['Boolean']['input']>;
  it?: InputMaybe<Scalars['Boolean']['input']>;
};

export type I18n_Id = {
  __typename?: 'i18n_ID';
  de?: Maybe<Scalars['ID']['output']>;
  en?: Maybe<Scalars['ID']['output']>;
  fr?: Maybe<Scalars['ID']['output']>;
  it?: Maybe<Scalars['ID']['output']>;
};

export type I18n_Id_Input = {
  de?: InputMaybe<Scalars['ID']['input']>;
  en?: InputMaybe<Scalars['ID']['input']>;
  fr?: InputMaybe<Scalars['ID']['input']>;
  it?: InputMaybe<Scalars['ID']['input']>;
};

export type I18n_Int = {
  __typename?: 'i18n_Int';
  de?: Maybe<Scalars['Int']['output']>;
  en?: Maybe<Scalars['Int']['output']>;
  fr?: Maybe<Scalars['Int']['output']>;
  it?: Maybe<Scalars['Int']['output']>;
};

export type I18n_Int_Input = {
  de?: InputMaybe<Scalars['Int']['input']>;
  en?: InputMaybe<Scalars['Int']['input']>;
  fr?: InputMaybe<Scalars['Int']['input']>;
  it?: InputMaybe<Scalars['Int']['input']>;
};

export type I18n_RichText = {
  __typename?: 'i18n_RichText';
  de?: Maybe<Scalars['RichText']['output']>;
  en?: Maybe<Scalars['RichText']['output']>;
  fr?: Maybe<Scalars['RichText']['output']>;
  it?: Maybe<Scalars['RichText']['output']>;
};

export type I18n_RichText_Input = {
  de?: InputMaybe<Scalars['RichText']['input']>;
  en?: InputMaybe<Scalars['RichText']['input']>;
  fr?: InputMaybe<Scalars['RichText']['input']>;
  it?: InputMaybe<Scalars['RichText']['input']>;
};

export type I18n_String = {
  __typename?: 'i18n_String';
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
  it?: Maybe<Scalars['String']['output']>;
};

export type I18n_String_Input = {
  de?: InputMaybe<Scalars['String']['input']>;
  en?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<Scalars['String']['input']>;
  it?: InputMaybe<Scalars['String']['input']>;
};

export type I18n_Media = {
  __typename?: 'i18n_media';
  de?: Maybe<Media>;
  en?: Maybe<Media>;
  fr?: Maybe<Media>;
  it?: Maybe<Media>;
};

export type I18n_Media_Input_Input = {
  de?: InputMaybe<Media_Input>;
  en?: InputMaybe<Media_Input>;
  fr?: InputMaybe<Media_Input>;
  it?: InputMaybe<Media_Input>;
};

export type ListByTypeConnection = {
  __typename?: 'listByTypeConnection';
  nodes: Array<Array<ListByTypeCustomContent>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ListByTypeCustomContent = {
  __typename?: 'listByTypeCustomContent';
  content: ContentModelSummary;
};

export type Media = {
  __typename?: 'media';
  focalPoint?: Maybe<Point>;
  media?: Maybe<Media_Detail>;
};

export type MediaMultiUploadInput = {
  altText?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MediaMultiUploadListItem = {
  __typename?: 'mediaMultiUploadListItem';
  altText: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  language: Scalars['String']['output'];
  mediaId: Scalars['ID']['output'];
  mediaIsNew: Scalars['Boolean']['output'];
  mediaIsNewForLanguage: Scalars['Boolean']['output'];
  modifiedAt: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Media_Detail = {
  __typename?: 'media_detail';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Media_Media_Image>;
  mimeType: Scalars['String']['output'];
  modifiedAt: Scalars['DateTime']['output'];
  transformURL?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type Media_DetailTransformUrlArgs = {
  input?: InputMaybe<ImageTransformation>;
};

export type Media_Input = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  focalPoint?: InputMaybe<InputPoint>;
  media?: InputMaybe<Scalars['Unknown']['input']>;
};

export type Media_Media_Image = {
  __typename?: 'media_media_image';
  format: Scalars['String']['output'];
  /** returns the height as int for rasterF images or float for svg */
  height: Scalars['Float']['output'];
  /** returns the width as int for raster images or float for svg */
  width: Scalars['Float']['output'];
};

export type Ref__CmpRef_BridgeProjectInnovationField = {
  __typename?: 'ref__cmpRef_bridgeProjectInnovationField';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_BridgeProjectInnovationField_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_BridgeProjectInstitution = {
  __typename?: 'ref__cmpRef_bridgeProjectInstitution';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_BridgeProjectInstitution_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_BridgeProjectType = {
  __typename?: 'ref__cmpRef_bridgeProjectType';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_BridgeProjectType_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_CategoryNews = {
  __typename?: 'ref__cmpRef_categoryNews';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_CategoryNews_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_CategoryNews_Module = {
  __typename?: 'ref__cmpRef_categoryNews_module';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSlider_FilterCriteriaCategories>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Contact = {
  __typename?: 'ref__cmpRef_contact';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Contact_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_EventType = {
  __typename?: 'ref__cmpRef_eventType';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_EventType_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Faq = {
  __typename?: 'ref__cmpRef_faq';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Faq_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Footer = {
  __typename?: 'ref__cmpRef_footer';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Footer_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Media = {
  __typename?: 'ref__cmpRef_media';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Media_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Module = {
  __typename?: 'ref__cmpRef_module';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Module_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Navigation = {
  __typename?: 'ref__cmpRef_navigation';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Navigation_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_News = {
  __typename?: 'ref__cmpRef_news';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_News_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_News_Project_Event_Publication = {
  __typename?: 'ref__cmpRef_news_project_event_publication';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserCarousel_Slider_Ref>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_News_Publication_Event = {
  __typename?: 'ref__cmpRef_news_publication_event';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Page_Record_Content_Blocks_OverviewResults_Results_Reference_Ref>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Page = {
  __typename?: 'ref__cmpRef_page';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Page_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Page_Multimedia = {
  __typename?: 'ref__cmpRef_page_multimedia';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Navigation_Record_Content_Navigation_ChildContents>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Page_News_Event_Media_Multimedia_Project = {
  __typename?: 'ref__cmpRef_page_news_event_media_multimedia_project';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Domain_Record_Content_Forwards_Target_Internal_Link>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Page_News_Event_Publication = {
  __typename?: 'ref__cmpRef_page_news_event_publication';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Page_Record_Content_Blocks_TeaserSection_Teaser_Manual_List_Reference_Ref>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Page_Project = {
  __typename?: 'ref__cmpRef_page_project';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_News_Record_Meta_PresentOn>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_PublicationType = {
  __typename?: 'ref__cmpRef_publicationType';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_PublicationType_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Tag = {
  __typename?: 'ref__cmpRef_tag';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Tag_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Tag_CategoryNews_Module_Domain_Website_Navigation_Footer_Media_Page_Multimedia_Project_Publication_PublicationType_News_Event_EventType_Faq_Contact_BridgeProject_BridgeProjectInstitution_BridgeProjectInnovationField_BridgeProjectType = {
  __typename?: 'ref__cmpRef_tag_categoryNews_module_domain_website_navigation_footer_media_page_multimedia_project_publication_publicationType_news_event_eventType_faq_contact_bridgeProject_bridgeProjectInstitution_bridgeProjectInnovationField_bridgeProjectType';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_CmpRef_Richtext_References>;
  recordId: Scalars['ID']['output'];
};

export type Ref__CmpRef_Website = {
  __typename?: 'ref__cmpRef_website';
  contentType: Scalars['ID']['output'];
  record?: Maybe<_Cmp_Website_Record>;
  recordId: Scalars['ID']['output'];
};

export type Ref_Input = {
  contentType: Scalars['ID']['input'];
  record?: InputMaybe<Scalars['Unknown']['input']>;
  recordId: Scalars['ID']['input'];
};

export enum Sort_BridgeProject {
  ContentKeywords = 'content__keywords',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_BridgeProjectInnovationField {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_BridgeProjectInstitution {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_BridgeProjectType {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_CategoryNews {
  ContentOrder = 'content__order',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Contact {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Domain {
  ContentDomains = 'content__domains',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Event {
  ContentEndDate = 'content__endDate',
  ContentStartDate = 'content__startDate',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_EventType {
  ContentOrder = 'content__order',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Faq {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Footer {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Media {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Module {
  ContentOrder = 'content__order',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Multimedia {
  ContentNewsDate = 'content__newsDate',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Navigation {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_News {
  ContentEntryDate = 'content__entryDate',
  ContentEntryDates = 'content__entryDates',
  ContentNewsDate = 'content__newsDate',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nContentEntryDateText = 'i18n__content__entryDateText',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Page {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Project {
  ContentProjectManagement = 'content__projectManagement',
  ContentStatus = 'content__status',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Publication {
  ContentDate = 'content__date',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_PublicationType {
  ContentOrder = 'content__order',
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Tag {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  I18nMetaMigrationsUrl = 'i18n__meta__migrationsUrl',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export enum Sort_Website {
  CreatedAt = 'createdAt',
  DePublicationDate = 'dePublicationDate',
  DeletionDate = 'deletionDate',
  ModifiedAt = 'modifiedAt',
  PublicationDate = 'publicationDate',
  Title = 'title'
}

export type UploadBridgeProjectsResponse = {
  __typename?: 'uploadBridgeProjectsResponse';
  response: Scalars['ID']['output'];
};

export type BlockImageFragment = { __typename?: '_cmp_news_record_content_blocks_image', image?: { __typename?: '_cmp_news_record_content_blocks_image_content', image?: { __typename?: 'ref__cmpRef_media', recordId: string, contentType: string } | null, caption: { __typename?: 'i18n_String', en?: string | null } } | null };

export type BlockRichTextFragment = { __typename?: '_cmp_news_record_content_blocks_richText', richText?: { __typename?: '_cmp_news_record_content_blocks_richText_content', text: { __typename?: 'i18n_RichText', en?: Node[] | null } } | null };

export type BlockLinkListFragment = { __typename?: '_cmp_news_record_content_blocks_linkList', linkList?: { __typename?: '_cmp_news_record_content_blocks_linkList_content', listType: _Cmp_News_Record_Content_Blocks_LinkList_ListType, title: { __typename?: 'i18n_String', en?: string | null, de?: string | null, fr?: string | null, it?: string | null }, linkList: Array<{ __typename?: '_cmp_news_record_content_blocks_linkList_linkList', linkText: { __typename?: 'i18n_String', en?: string | null }, link: { __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_external', external?: { __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_external_content', target?: _Cmp_News_Record_Content_Blocks_LinkList_LinkList_Link_External_Target | null, nofollow: boolean, href: { __typename?: 'i18n_String', de?: string | null, fr?: string | null, en?: string | null, it?: string | null } } | null } | { __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_internal', internal?: { __typename?: '_cmp_news_record_content_blocks_linkList_linkList_link_internal_content', link?: { __typename?: 'ref__cmpRef_page_news_event_media_multimedia_project', recordId: string, contentType: string } | null } | null } }> } | null };

export type BlockAccordionFragment = { __typename?: '_cmp_news_record_content_blocks_accordion', accordion?: { __typename?: '_cmp_news_record_content_blocks_accordion_content', accordion: Array<{ __typename?: '_cmp_news_record_content_blocks_accordion_accordion', title: { __typename?: 'i18n_String', en?: string | null }, content: Array<{ __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_anchor' } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_codeSnippet' } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_faqList' } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_image', image?: { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_image_content', image?: { __typename?: 'ref__cmpRef_media', recordId: string } | null } | null } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_linkList' } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_news' } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_richText', richText?: { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_richText_content', text: { __typename?: 'i18n_RichText', en?: Node[] | null } } | null } | { __typename?: '_cmp_news_record_content_blocks_accordion_accordion_content_table' }> }> } | null };

export type FetchMultiUploadQueryVariables = Exact<{
  files: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type FetchMultiUploadQuery = { __typename?: 'Query', extensions: { __typename?: 'CustomQuery', mediaMultiUpload: Array<{ __typename?: 'mediaMultiUploadListItem', mediaId: string, fileName: string, title: string, language: string, altText: string, createdAt: string, modifiedAt: string, mediaIsNewForLanguage: boolean, mediaIsNew: boolean }> } };

export type MultiUploadFileMutationVariables = Exact<{
  file?: InputMaybe<Scalars['Upload']['input']>;
  filename: Scalars['String']['input'];
  title: Scalars['String']['input'];
  altText: Scalars['String']['input'];
}>;


export type MultiUploadFileMutation = { __typename?: 'Mutation', extensions: { __typename?: 'CustomMutation', mediaMultiUploadFile: { __typename?: 'mediaMultiUploadListItem', mediaId: string, fileName: string, title: string, language: string, altText: string, createdAt: string, mediaIsNew: boolean, mediaIsNewForLanguage: boolean } } };

export type ReindexMutationVariables = Exact<{ [key: string]: never; }>;


export type ReindexMutation = { __typename?: 'Mutation', extensions: { __typename?: 'CustomMutation', reindex?: { __typename?: 'Migrate', message: string } | null } };

export type UploadBridgeProjectsMutationVariables = Exact<{
  input: UploadCsvInput;
}>;


export type UploadBridgeProjectsMutation = { __typename?: 'Mutation', extensions: { __typename?: 'CustomMutation', uploadBridgeProjects: { __typename?: 'uploadBridgeProjectsResponse', response: string } } };

export const BlockImageFragmentDoc = gql`
    fragment blockImage on _cmp_news_record_content_blocks_image {
  image {
    image {
      recordId
      contentType
    }
    caption {
      en
    }
  }
}
    `;
export const BlockRichTextFragmentDoc = gql`
    fragment blockRichText on _cmp_news_record_content_blocks_richText {
  richText {
    text {
      en
    }
  }
}
    `;
export const BlockLinkListFragmentDoc = gql`
    fragment blockLinkList on _cmp_news_record_content_blocks_linkList {
  linkList {
    title {
      en
      de
      fr
      it
    }
    listType
    linkList {
      linkText {
        en
      }
      link {
        ... on _cmp_news_record_content_blocks_linkList_linkList_link_internal {
          internal {
            link {
              recordId
              contentType
            }
          }
        }
        ... on _cmp_news_record_content_blocks_linkList_linkList_link_external {
          external {
            href {
              de
              fr
              en
              it
            }
            target
            nofollow
          }
        }
      }
    }
  }
}
    `;
export const BlockAccordionFragmentDoc = gql`
    fragment blockAccordion on _cmp_news_record_content_blocks_accordion {
  accordion {
    accordion {
      title {
        en
      }
      content {
        ... on _cmp_news_record_content_blocks_accordion_accordion_content_richText {
          richText {
            text {
              en
            }
          }
        }
        ... on _cmp_news_record_content_blocks_accordion_accordion_content_image {
          image {
            image {
              recordId
            }
          }
        }
      }
    }
  }
}
    `;
export const FetchMultiUploadDocument = gql`
    query fetchMultiUpload($files: [String]!) {
  extensions {
    mediaMultiUpload(fileList: $files) {
      mediaId
      fileName
      title
      language
      altText
      createdAt
      modifiedAt
      mediaIsNewForLanguage
      mediaIsNew
    }
  }
}
    `;

/**
 * __useFetchMultiUploadQuery__
 *
 * To run a query within a React component, call `useFetchMultiUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMultiUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMultiUploadQuery({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useFetchMultiUploadQuery(baseOptions: Apollo.QueryHookOptions<FetchMultiUploadQuery, FetchMultiUploadQueryVariables> & ({ variables: FetchMultiUploadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>(FetchMultiUploadDocument, options);
      }
export function useFetchMultiUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>(FetchMultiUploadDocument, options);
        }
export function useFetchMultiUploadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>(FetchMultiUploadDocument, options);
        }
export type FetchMultiUploadQueryHookResult = ReturnType<typeof useFetchMultiUploadQuery>;
export type FetchMultiUploadLazyQueryHookResult = ReturnType<typeof useFetchMultiUploadLazyQuery>;
export type FetchMultiUploadSuspenseQueryHookResult = ReturnType<typeof useFetchMultiUploadSuspenseQuery>;
export type FetchMultiUploadQueryResult = Apollo.QueryResult<FetchMultiUploadQuery, FetchMultiUploadQueryVariables>;
export const MultiUploadFileDocument = gql`
    mutation MultiUploadFile($file: Upload, $filename: String!, $title: String!, $altText: String!) {
  extensions {
    mediaMultiUploadFile(
      input: {file: $file, filename: $filename, title: $title, altText: $altText}
    ) {
      mediaId
      fileName
      title
      language
      altText
      createdAt
      mediaIsNew
      mediaIsNewForLanguage
    }
  }
}
    `;
export type MultiUploadFileMutationFn = Apollo.MutationFunction<MultiUploadFileMutation, MultiUploadFileMutationVariables>;

/**
 * __useMultiUploadFileMutation__
 *
 * To run a mutation, you first call `useMultiUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiUploadFileMutation, { data, loading, error }] = useMultiUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *      title: // value for 'title'
 *      altText: // value for 'altText'
 *   },
 * });
 */
export function useMultiUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<MultiUploadFileMutation, MultiUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultiUploadFileMutation, MultiUploadFileMutationVariables>(MultiUploadFileDocument, options);
      }
export type MultiUploadFileMutationHookResult = ReturnType<typeof useMultiUploadFileMutation>;
export type MultiUploadFileMutationResult = Apollo.MutationResult<MultiUploadFileMutation>;
export type MultiUploadFileMutationOptions = Apollo.BaseMutationOptions<MultiUploadFileMutation, MultiUploadFileMutationVariables>;
export const ReindexDocument = gql`
    mutation Reindex {
  extensions {
    reindex {
      message
    }
  }
}
    `;
export type ReindexMutationFn = Apollo.MutationFunction<ReindexMutation, ReindexMutationVariables>;

/**
 * __useReindexMutation__
 *
 * To run a mutation, you first call `useReindexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReindexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reindexMutation, { data, loading, error }] = useReindexMutation({
 *   variables: {
 *   },
 * });
 */
export function useReindexMutation(baseOptions?: Apollo.MutationHookOptions<ReindexMutation, ReindexMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReindexMutation, ReindexMutationVariables>(ReindexDocument, options);
      }
export type ReindexMutationHookResult = ReturnType<typeof useReindexMutation>;
export type ReindexMutationResult = Apollo.MutationResult<ReindexMutation>;
export type ReindexMutationOptions = Apollo.BaseMutationOptions<ReindexMutation, ReindexMutationVariables>;
export const UploadBridgeProjectsDocument = gql`
    mutation UploadBridgeProjects($input: UploadCsvInput!) {
  extensions {
    uploadBridgeProjects(input: $input) {
      response
    }
  }
}
    `;
export type UploadBridgeProjectsMutationFn = Apollo.MutationFunction<UploadBridgeProjectsMutation, UploadBridgeProjectsMutationVariables>;

/**
 * __useUploadBridgeProjectsMutation__
 *
 * To run a mutation, you first call `useUploadBridgeProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBridgeProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBridgeProjectsMutation, { data, loading, error }] = useUploadBridgeProjectsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadBridgeProjectsMutation(baseOptions?: Apollo.MutationHookOptions<UploadBridgeProjectsMutation, UploadBridgeProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBridgeProjectsMutation, UploadBridgeProjectsMutationVariables>(UploadBridgeProjectsDocument, options);
      }
export type UploadBridgeProjectsMutationHookResult = ReturnType<typeof useUploadBridgeProjectsMutation>;
export type UploadBridgeProjectsMutationResult = Apollo.MutationResult<UploadBridgeProjectsMutation>;
export type UploadBridgeProjectsMutationOptions = Apollo.BaseMutationOptions<UploadBridgeProjectsMutation, UploadBridgeProjectsMutationVariables>;