/* eslint-disable react/display-name */
import {EditorConfig, ExtensionType} from '@dudagroup/editor'
import React from 'react'
import {CustomViewReindex} from './customViews/customView'
import CustomViewFilesUpload from './customViews/customViewFilesUpload'
import {CustomViewPurgecache} from './customViews/customViewPurgecache'
import {getNavigationView} from './models/navigation/navigationView'
import {getGenericViewWithCustomBlockList, getPreviewLink} from './models/page/pageContentView'

import Tag from '@rsuite/icons/legacy/Tag'
import ObjectUngroup from '@rsuite/icons/legacy/ObjectUngroup'
import Globe from '@rsuite/icons/legacy/Globe'
import Home from '@rsuite/icons/legacy/Home'
import Sitemap from '@rsuite/icons/legacy/Sitemap'
import AngleDoubleDown from '@rsuite/icons/legacy/AngleDoubleDown'
import FileImageO from '@rsuite/icons/legacy/FileImageO'
import FileTextO from '@rsuite/icons/legacy/FileTextO'
import Project from '@rsuite/icons/legacy/Project'
import Book from '@rsuite/icons/legacy/Book'
import NewspaperO from '@rsuite/icons/legacy/NewspaperO'
import Calendar from '@rsuite/icons/legacy/Calendar'
import Question2 from '@rsuite/icons/legacy/Question2'
import Film from '@rsuite/icons/legacy/Film'
import AddressBookO from '@rsuite/icons/legacy/AddressBookO'
import {CustomViewBridgeProjoectsImporter} from './customViews/customViewBridgeProjectImporter'
import PreviewLinkCopy from './PreviewLinkCopy'

export const MODEL_MEDIA = 'media'
export const LANGUAGES = ['de', 'fr', 'en', 'it']
export const DEFAULT_LANGUAGE = 'de'

export const config: EditorConfig = {
  navigationBar: {},
  extensions: [
    {
      identifier: 'domain',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Domain',
      namePlural: 'Domains',
      icon: <Globe />,
    },
    {
      identifier: 'website',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Website',
      namePlural: 'Websites',
      icon: <Home />,
    },
    {
      identifier: 'navigation',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Navigation',
      namePlural: 'Navigations',
      icon: <Sitemap />,
      getContentView: getNavigationView,
    },
    {
      identifier: 'footer',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Footer',
      namePlural: 'Footers',
      icon: <AngleDoubleDown />,
    },
    {
      identifier: 'tag',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Tag',
      namePlural: 'Tags',
      icon: <Tag />,
    },
    {
      identifier: MODEL_MEDIA,
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Media',
      namePlural: 'Media',
      customColumns: [
        {
          headerTranslationKey: 'Vorschau',
          previewPaths: [['content', 'media']],
          useAsPreviewInDetailView: true,
        },
        {
          headerTranslationKey: 'Preview Links',
          fetchEnrichedContent: true,
          getCustomViewContent: (content, contentDefaultLang) => {
            return (
              <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} isMedia={true} />
            )
          },
        },
      ],
      previewSize: 'big',
      icon: <FileImageO />,
      getPreviewLink: (token, language, recordData) => {
        const settings = document.getElementById('settings')
        const media = recordData?.content?.media?.[language]?.media
        if (settings?.textContent && media?.filename && media?.extension) {
          const {mediaPreviewUrl} = JSON.parse(settings.textContent)

          if (
            recordData.meta.robotsMetaDirectives === 'indexFollow' ||
            recordData.meta.robotsMetaDirectives === 'indexNoFollow' ||
            !recordData.meta.robotsMetaDirectives ||
            recordData.content.media[language].media
          ) {
            return `${mediaPreviewUrl}/media/${language}/${recordData.id}/${media.filename}${media.extension}`
          }
          return `${mediaPreviewUrl}/media-noindex/${language}/${recordData.id}/${media.filename}${media.extension}`
        }

        return null
      },
      disableDuplication: true,
    },
    {
      identifier: 'fileuploader',
      type: ExtensionType.CusomExtension,
      nameSingular: {
        de: 'Medien hochladen',
        en: 'Upload media',
      },
      namePlural: {
        de: 'Medien hochladen',
        en: 'Upload media',
      },
      icon: <FileImageO />,
      view: <CustomViewFilesUpload />,
    },
    {
      identifier: 'page',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Seite',
        en: 'Page',
      },
      namePlural: {
        de: 'Seiten',
        en: 'Pages',
      },
      icon: <FileTextO />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',

          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'project',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Projekt',
        en: 'Project',
      },
      namePlural: {
        de: 'Projekte',
        en: 'Projects',
      },
      icon: <Project />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',

          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'module',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Modul',
        en: 'Module',
      },
      namePlural: {
        de: 'Module',
        en: 'Modules',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'publication',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Publikation',
        en: 'Publication',
      },
      namePlural: {
        de: 'Publikationen',
        en: 'Publications',
      },
      icon: <Book />,
      deriveSlug: {
        instructions: 'derive from content title',
        jsonPath: '$.content.title',
      },
    },
    {
      identifier: 'publicationType',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Publikation Typ',
        en: 'Publication Type',
      },
      namePlural: {
        de: 'Publikationstypen',
        en: 'Publication types',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'news',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'News',
      namePlural: 'News',
      icon: <NewspaperO />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',

          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'categoryNews',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'News Kategorie',
        en: 'News Category',
      },
      namePlural: {
        de: 'News Kategorien',
        en: 'News Categories',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'event',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Event',
      namePlural: 'Events',
      icon: <Calendar />,
      getContentView: getGenericViewWithCustomBlockList,
      getPreviewLink: getPreviewLink,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',

          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'eventType',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Event Typ',
        en: 'Event Type',
      },
      namePlural: {
        de: 'Eventtypen',
        en: 'Event types',
      },
      icon: <ObjectUngroup />,
    },
    {
      identifier: 'faq',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'FAQ',
      namePlural: 'FAQs',
      icon: <Question2 />,
    },
    {
      identifier: 'multimedia',
      type: ExtensionType.ContentModelExtension,
      nameSingular: 'Multimedia',
      getPreviewLink: getPreviewLink,
      namePlural: 'Multimedia',
      icon: <Film />,
      deriveSlug: {
        instructions: 'derive from meta title',
        jsonPath: '$.meta.title',
      },
      customColumns: [
        {
          headerTranslationKey: 'Preview Link',

          getCustomViewContent: (content, contentDefaultLang) => {
            return <PreviewLinkCopy content={content} defaultLang={contentDefaultLang} />
          },
        },
      ],
    },
    {
      identifier: 'contact',
      type: ExtensionType.ContentModelExtension,
      nameSingular: {
        de: 'Kontakt',
        en: 'Contact',
      },
      namePlural: {
        de: 'Kontakte',
        en: 'Contacts',
      },
      icon: <AddressBookO />,
    },
    {
      identifier: 'reindex',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Reindex',
      namePlural: 'Reindex',
      view: <CustomViewReindex />,
    },
    {
      identifier: 'cache',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Cache',
      namePlural: 'Cache',
      view: <CustomViewPurgecache />,
    },
    {
      identifier: 'bridgeProjectImporter',
      type: ExtensionType.CusomExtension,
      nameSingular: 'Bridge Project Importer',
      namePlural: 'Bridge Project Importer',
      view: <CustomViewBridgeProjoectsImporter />,
    },
  ],
}
